import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-debug-form',
  templateUrl: './debug-form.component.html',
  styleUrls: ['./debug-form.component.scss']
})
export class DebugFormComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() raw: boolean = false;
  get hidden(): boolean {
    return environment.production;
  }
  constructor() {}

  ngOnInit(): void {}
}
