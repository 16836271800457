import { AngularFirestore } from '@angular/fire/firestore';
import { BaseService } from '../base/base.service';
import { Injectable } from '@angular/core';
import { Availability } from '@models';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class AvailabilityService extends BaseService<Availability> {
  protected readonly documentPath = 'availabilities';

  constructor(
    protected db: AngularFirestore,
    protected toastService: ToastService
  ) {
    super(db, toastService);
  }
}
