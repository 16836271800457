import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { WebsitePageType } from '@models';
import { BaseService, ToastService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class WebsitePageTypeService extends BaseService<WebsitePageType> {
  protected readonly documentPath = 'websitePageTypes';

  constructor(
    protected db: AngularFirestore,
    protected toastService: ToastService
  ) {
    super(db, toastService);
  }
}
