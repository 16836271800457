<div class="modal-header">
  <h4 class="modal-title">Team keuzemenu</h4>
  <button type="button" class="close" nofocus (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="form-group">
    <div class="d-flex justify-content-between">
      <label class="input__label mb-0">Kies een team uit de lijst</label>
    </div>
    <select class="custom-select d-block w-100" [(ngModel)]="selectedId">
      <option selected [ngValue]="null">{{defaultValue}}</option>
      <option *ngFor="let t of teams" [ngValue]="t.globalId">{{t.teamnaam}}</option>
    </select>
    <small class="form-text text-muted"></small>
  </div>

  <ngb-alert [dismissible]="false" type="info" title="Geen team"><i class="fas fa-info-circle"></i> Is de keuzelijst
    leeg en kun je geen team
    selecteren? Neem dan contact op met support via <a
       href="mailto:support@intellicare.nl">support@intellicare.nl</a>.
    <br/>
    <small class="form-text text-muted"> Ingelogd als: {{fbUser.email}} ({{user.email}})</small>
  </ngb-alert>


</div>
<div class="modal-footer">
  <ng-container>
    <button type="button" class="btn btn-secondary" (click)="cancel()">Annuleren</button>
  </ng-container>
  <ng-container>
    <button type="button" class="btn btn-success" (click)="save()">Opslaan</button>
  </ng-container>
</div>
