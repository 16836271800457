<div class="modal-header">
  <h4 class="modal-title">Werken bij Buurtzorg</h4>
  <button type="button" class="close" nofocus (click)="activeModal.dismiss('close')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div *ngIf="!story.videoUrl && story.imageUrl">
    <img [src]="story.imageUrl" class="img-fluid">
    <div class="image-overlay text-white px-4 py-2 text-center">
      <p class="m-0 h4 font-weight-bold">{{story.title}}</p>
      <p class="h4">{{story.subtitle}}</p>
    </div>
  </div>

  <div class="video-wrapper" *ngIf="story.videoUrl">
    <div class="embed-responsive embed-responsive-16by9">
      <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(story.videoUrl)" allowfullscreen="allowfullscreen"
              class="embed-responsive-item"></iframe>

    </div>
  </div>

  <p class="lead text-center mt-4">{{story.lead}}</p>

  <p [innerHTML]="story.body"></p>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.close('close')">Sluiten</button>
</div>
