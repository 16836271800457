<div class="form-group">
	<div class="d-flex justify-content-between">
		<label class="input__label mb-0">{{ label }}</label>
		<!-- <small class="text-muted required-label">verplicht</small> -->
	</div>
	<ng-content></ng-content>
	<small class="form-text text-muted" *ngIf="explanation">
		{{ explanation }}
	</small>
	<app-validation-message *ngIf="control != null && !hideValidationMessage" [control]="control"></app-validation-message>
</div>
