import { UserService } from './../services/api/user.service';
import { AuthService } from '@auth';
import { Injectable, OnDestroy } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanActivateChild,
} from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { LogService } from '../helpers/log.service';
import { map, filter, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, OnDestroy {
  protected readonly unsubscribe$ = new Subject();

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private logService: LogService
  ) {}
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.isUserAuthenticated(state);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.isUserAuthenticated(state);
  }

  isUserAuthenticated(state: RouterStateSnapshot) {
    return this.authService.firebaseUser$.pipe(
      filter((fbUser) => fbUser !== undefined),
      tap((fbUser) => this.logService.log(fbUser)),
      map((fbUser) => {
        if (!fbUser) {
          const urlTree = this.router.parseUrl('/login');
          urlTree.queryParams = { returnUrl: state.url };
          return urlTree;
        } else {
          return true;
        }
      })
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }
}
