import { LogService } from './../../core/helpers/log.service';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import {
  FormGroup,
  ControlValueAccessor,
  FormControl,
  Validators,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormBuilder,
} from '@angular/forms';
import { FormComponentBase } from '@base';
import { Component, forwardRef } from '@angular/core';
import { phoneValidator, emailValidator } from 'src/app/core/validators';
import { FormMode } from '@enums';
import { Observable } from 'rxjs';

export interface UserProfileFormValues {
  gender: string;
  initials: string;
  firstname: string;
  lastnamePrefix: string;
  lastname: string;
  email: string;
  phonenumber: string;
  // curriculumVitaeUrl: string;
  // notes: string;
}

@Component({
  selector: 'app-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserProfileFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UserProfileFormComponent),
      multi: true,
    },
  ],
})
export class UserProfileFormComponent
  extends FormComponentBase
  implements ControlValueAccessor {
  formMode: FormMode = FormMode.Create;
  documentId: string;
  uploadPercent$: Observable<number>;
  form: FormGroup;
  filename: string = null;
  disabled: boolean;

  get value(): UserProfileFormValues {
    return this.form.value;
  }

  set value(value: UserProfileFormValues) {
    this.form.setValue(value, { emitEvent: false });
    this.onChange(value);
    this.onTouched();
  }

  constructor(
    protected route: ActivatedRoute,
    private fb: FormBuilder,
    private logService: LogService
  ) {
    super(route);

    this.createForm();

    // any time the inner form changes update the parent of any change
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.onChange(value);
        this.onTouched();
      });
  }

  createForm() {
    this.form = this.fb.group({
      gender: new FormControl(null, [Validators.required]),
      initials: new FormControl(null, [Validators.required]),
      firstname: new FormControl(null, [Validators.required]),
      lastnamePrefix: new FormControl(null),
      lastname: new FormControl(null, [Validators.required]),
      phonenumber: new FormControl(null, [Validators.required, phoneValidator]),
      email: new FormControl(null, [Validators.required, emailValidator]),
    });

    this.trackFormValidity();
  }

  // ==========================
  // ControlValueAccessor
  // ==========================
  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }

    if (obj === null) {
      this.form.reset();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  // ==========================

  validate(control: FormControl) {
    this.logService.warning('validate() control:', control);

    this.logService.info('validate() validator: ', control.validator);

    this.logService.info('Form.parent:', this.form.parent);

    if (!this.form.valid && control.touched) {
      this.invalidateForm({ onlySelf: true, emitEvent: false });
      return this.form.valid ? null : { profile: { valid: false } };
    }

    return null;
  }
}
