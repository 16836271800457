import { FullnamePipe } from './../../core/pipes/fullname.pipe';
import { Component, OnInit, Input } from '@angular/core';
import { JobApplication, Team } from '@models';

@Component({
  selector: 'app-job-applications-table',
  templateUrl: './job-applications-table.component.html',
  styleUrls: ['./job-applications-table.component.scss'],
})
export class JobApplicationsTableComponent implements OnInit {
  @Input() jobApplications: JobApplication[];
  @Input() team: Team;

  page = 1;
  pageSize = 20;

  constructor() {}

  ngOnInit(): void {}
}
