import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Team, User } from '@models';
import { ComponentBase } from '@base';
import { TeamService, UserService } from '@services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth';
import { LogService } from 'src/app/core/helpers/log.service';
import { QueryFn } from '@angular/fire/firestore';
import firebase from 'firebase/app';

@Component({
  selector: 'app-team-selection-modal',
  templateUrl: './team-selection-modal.component.html',
  styleUrls: ['./team-selection-modal.component.scss']
})
export class TeamSelectionModalComponent extends ComponentBase implements OnInit {
  @Input() team: Team;
  @Input() defaultValue = '- Kies een team -';
  teams: Team[];
  selectedId = '';
  user: User;
  fbUser: firebase.User;

  constructor(
    private teamService: TeamService,
    public activeModal: NgbActiveModal,
    protected route: ActivatedRoute,
    protected authService: AuthService,
    private userService: UserService,
    private logService: LogService
  ) {
    super(route);

    this.authService.user$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
    this.authService.firebaseUser$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((fbUser) => {
      if (fbUser) {
        this.fbUser = fbUser;
      }
    });

    this.teamService.authorizedTeams$.pipe(takeUntil(this.unsubscribe$)).subscribe((teams) => {
      this.teams = teams;
    });
  }

  ngOnInit() {
    if (this.team) {
      this.selectedId = this.team.globalId;
    } else {
      this.selectedId = null;
    }
  }

  cancel() {
    this.activeModal.close('');
  }

  save() {
    const selectedTeam = this.teams.find((team) => team.globalId === this.selectedId);
    if (selectedTeam) {
      this.activeModal.close(selectedTeam);
    } else {
      this.activeModal.close(null);
    }
  }
}
