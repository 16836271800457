import { EsSearchRequest, EsQuery, EsSort } from '@models';
import { SortDirection } from './sort-direction';
import { SortOrder } from './sort-order';

export type GeoCoordinates = { lat: number; lon: number };
export type GeoAddresses = { plaats: string; gemeente: string; provincie: string; lat: number; lon: number };

export class EsSearchQuery {
	private searchTerm: string;
	private availabilities: string[] = [];
	private jobTitles: string[] = [];
	private distance: string;
	private geoCoordinates: GeoCoordinates;

	// private sortOrders: SortOrder[] = [];
	private orderby: SortOrder;
	private skip = 0;
	private pageNumber = 1;

	// tslint:disable-next-line:variable-name
	private _pageSize = 10;

	public get pageSize() {
		return this._pageSize;
	}

	public set pageSize(value: number) {
		this._pageSize = value;
		this.setPage(this.pageNumber);
	}

	constructor() {}

	addAvailibity(availability: string) {
		this.availabilities.push(availability);
		return this;
	}

	setAvailabilities(availabilityIds: string[]) {
		this.availabilities = availabilityIds;
		return this;
	}

	removeAvailibity(availibity: string) {
		const index = this.availabilities.indexOf(availibity);
		this.availabilities.splice(index, 1);
		return this;
	}

	addJobTitle(jobTitle: string) {
		this.jobTitles.push(jobTitle);
		return this;
	}

	setJobTitles(jobTitleIds: string[]) {
		this.jobTitles = jobTitleIds;
		return this;
	}

	removeJobTitle(jobTitle: string) {
		const index = this.jobTitles.indexOf(jobTitle);
		this.jobTitles.splice(index, 1);
		return this;
	}

	setPage(pageNumber: number) {
		this.skip = (pageNumber - 1) * this.pageSize;
		this.pageNumber = pageNumber;
		return this;
	}

	setDistance(distance: string) {
		if (distance !== '') {
			this.distance = distance;
		} else {
			this.distance = null;
		}
		return this;
	}

	setGeoCoordinates(geoCoordinates: GeoCoordinates) {
		this.geoCoordinates = geoCoordinates;
		return this;
	}

	setOrderby(orderBy: string) {
		if (orderBy) {
			const sortOrder: SortOrder = {
				field: orderBy,
				value: SortDirection.Ascending
			};

			if (orderBy === 'createdDate') {
				sortOrder.value = SortDirection.Descending;
			}

			this.orderby = sortOrder;
		} else {
			this.orderby = null;
		}
	}

	setOrderByGeo(lat: number, lon: number) {
		const sortOrder: SortOrder = {
			field: '_geo_distance',
			value: {
				teamLocation: {
					lat,
					lon
				},
				order: 'asc',
				unit: 'km'
			}
		};
		this.orderby = sortOrder;
	}

	setSearchTerm(searchTerm: string) {
		this.searchTerm = searchTerm;
		return this;
	}

	getSearchParameters(): EsSearchRequest {
		const esRequest = {} as EsSearchRequest;
		esRequest.query = {
			bool: {}
		} as EsQuery;

		const bool = esRequest.query.bool;

		// esRequest.highlight = {
		//   pre_tags: ['<span class="text-primary font-weight-bold">'],
		//   post_tags: ['</span>'],
		//   number_of_fragments: 5,
		//   fragment_size: 100,
		//   boundary_max_scan: 20,
		//   order: 'score',
		//   fields: {
		//     titel: {},
		//     omschrijving: {},
		//   },
		// } as EsHighlight;

		const must = (bool.must = []);

		if (this.searchTerm && this.searchTerm !== '') {
			const multiMatch = { multi_match: { query: this.searchTerm } };
			must.push(multiMatch);
		}

		if (this.availabilities && this.availabilities.length > 0) {
			const availabilities = { terms: { availabilityId: [] } };
			this.availabilities.forEach((item) => {
				availabilities.terms.availabilityId.push(item);
			});
			must.push(availabilities);
		}

		if (this.jobTitles && this.jobTitles.length > 0) {
			const jobTitles = { terms: { jobTitleId: [] } };
			this.jobTitles.forEach((item) => {
				jobTitles.terms.jobTitleId.push(item);
			});
			must.push(jobTitles);
		}

		if (this.geoCoordinates) {
			const filter = (bool.filter = {} as EsQuery);
			filter.geo_distance = {
				distance: this.distance,
				teamLocation: this.geoCoordinates
			};
		}

		// esRequest.query.bool.filter = {} as EsQuery;
		// esRequest.query.bool.filter.bool = {} as EsBool;
		// esRequest.query.bool.filter.bool.must = [] as EsQuery[];
		// esRequest.query.bool.filter.bool.must.push({
		//   term: { organisatieIds: this.organisatieId.toString() },
		// });

		// esRequest.query.bool.filter.bool.should = [] as EsQuery[];
		// if (this.availibities !== undefined && this.availibities.length > 0) {
		//   this.availibities.forEach((a) => {
		//     esRequest.query.bool.filter.bool.should.push({
		//       term: { availibilityId: a },
		//     });
		//   });
		// }

		// if (this.jobTitles !== undefined && this.jobTitles.length > 0) {
		//   this.jobTitles.forEach((j) => {
		//     esRequest.query.bool.filter.bool.should.push({
		//       term: { jobTitleId: j },
		//     });
		//   });
		// }

		esRequest.sort = [] as EsSort[];

		if (this.orderby) {
			esRequest.sort.push({ [this.orderby.field]: this.orderby.value });
		}

		if (esRequest.query.bool?.filter?.bool?.should?.length > 0) {
			esRequest.query.bool.filter.bool.minimum_should_match = 1;
		}

		esRequest.size = this.pageSize;
		esRequest.from = this.skip;

		return esRequest;
	}
}
