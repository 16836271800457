import { HttpClient, HttpParams } from '@angular/common/http';
// https://maps.googleapis.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=YOUR_API_KEY
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { LogService } from '../../helpers/log.service';
import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService implements OnDestroy {
  private readonly googleApiUrl =
    'https://maps.googleapis.com/maps/api/geocode/json';
  private readonly geoCodingApiKey = 'AIzaSyC2NOGzOsq24KyaKwt2ESsjN78rclO8sK4';
  private readonly mapsApiKey = 'AIzaSyCl9WHRbl1FdeKyaVtOoebHiGOBNcba53k';

  public isGoogleMapsLoaded = this.lazyLoadGoogleMapsJavascript();

  constructor(private httpClient: HttpClient, private logService: LogService) {}

  ngOnDestroy() {}

  GeocodeFromAddress(address: string) {
    return this.httpClient.get<any>(this.googleApiUrl, {
      params: {
        address,
        key: this.geoCodingApiKey,
      },
    });
  }

  CityFromGeocode(geocode: any) {
    return this.httpClient.get<any>(this.googleApiUrl, {
      params: {
        latlng: `${geocode.coords.latitude}, ${geocode.coords.longitude}`,
        key: this.geoCodingApiKey,
      },
    });
  }

  private lazyLoadGoogleMapsJavascript() {
    return this.httpClient
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?key=' + this.mapsApiKey,
        'callback'
      )
      .pipe(
        map((obj) => {
          this.logService.success('Google Map successfully lazy loaded!', obj);
          return true;
        }),
        catchError((err) => {
          this.logService.danger('error loading Google Map: ', err);
          return of(false);
        }),
        shareReplay(1)
      );
  }
}
