import { switchMap, take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit, Pipe } from '@angular/core';
import { ComponentBase } from '@base';
import { TeamService, VacancyService, WebsiteService } from '@services';
import { Vacancy, Website, WebsitePage } from '@models';
import { concat, iif, merge, Observable, of, OperatorFunction } from 'rxjs';
import { LogService } from 'src/app/core/helpers/log.service';

@Component({
  selector: 'app-team-vacancies',
  templateUrl: './team-vacancies.component.html',
  styleUrls: ['./team-vacancies.component.scss'],
})
export class TeamVacanciesComponent extends ComponentBase implements OnInit {
  vacancies: Vacancy[];
  @Input() website: Website;

  constructor(
    protected route: ActivatedRoute,
    private websiteService: WebsiteService,
    private teamService: TeamService,
    private vacancyService: VacancyService,
    private logService: LogService
  ) {
    super(route);
  }

  ngOnInit(): void {
    this.vacancies = [];

    iif(
      () => !this.website,
      this.websiteService.getWebsiteFromSubdomain().pipe(take(1)),
      of(this.website)
    )
      .pipe(
        switchMap((website) => {
          if (website) {
            this.website = website;
            return this.websiteService
              .getWithQuery((query) =>
                query.where('redirectTo', '==', this.website.subdomain)
              )
              .pipe(take(1));
          } else {
            return of(null);
          }
        }),
        switchMap((websites) => {
          this.logService.info(`redirected websites:`, websites);
          let requests = websites.map((w) =>
            this.vacancyService.getTeamVacancies(w.teamId).pipe(take(1))
          );

          // collectie van observables
          return merge(
            ...requests,
            this.vacancyService
              .getTeamVacancies(this.website.teamId)
              .pipe(take(1))
          );
        })
      )
      .subscribe((vacancies) => {
        if (vacancies) this.vacancies.push(...vacancies);
      });
  }
}
