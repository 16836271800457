<div
	class="validation-message p-2 text-danger"
	*ngIf="control && control.invalid && control.dirty && control.touched && !control.hasError('nothingSelected')">
	<i class="fas fa-exclamation-triangle align-middle mr-1"></i>
	<ng-container *ngIf="customMessage">
		{{ customMessage }}
	</ng-container>
	<ng-container *ngIf="!customMessage">
		<ng-container *ngIf="control.hasError('required')"> Veld is verplicht </ng-container>
		<ng-container *ngIf="control.hasError('max')"> Maximale waarde is {{ control.errors['max'].max }} </ng-container>
		<ng-container *ngIf="control.hasError('min')"> Minimale waarde is {{ control.errors['min'].min }} </ng-container>
		<ng-container *ngIf="control.hasError('maxlength')">
			Maximaal aantal karakters is {{ control.errors['maxlength'].requiredLength }}
		</ng-container>
		<ng-container *ngIf="control.hasError('minlength')">
			Minimaal aantal karakters is {{ control.errors['minlength'].requiredLength }}
		</ng-container>
		<ng-container *ngIf="control.hasError('customUrl')"> Ongeldige url </ng-container>
		<ng-container *ngIf="control.hasError('customVideoUrl')">
			{{ control.errors['customVideoUrl'].message }}
		</ng-container>
		<ng-container *ngIf="control.hasError('email')"> Ongeldig e-mailadres </ng-container>
		<ng-container *ngIf="control.hasError('customEmail')"> Ongeldig e-mailadres </ng-container>
		<ng-container *ngIf="control.hasError('customPhone')"> Ongeldig telefoonnummer </ng-container>
		<ng-container *ngIf="control.hasError('customDutchZipcode')"> Ongeldige postcode </ng-container>
		<ng-container *ngIf="control.hasError('customPassword')">
			{{ control.errors['customPassword'].message }}
		</ng-container>
		<ng-container *ngIf="control.hasError('customDateComparison')">
			{{ control.errors['customDateComparison'].message }}
		</ng-container>
		<ng-container *ngIf="control.hasError('customTimeComparison')">
			{{ control.errors['customTimeComparison'].message }}
		</ng-container>
		<ng-container *ngIf="control.hasError('customNumberComparison')">
			{{ control.errors['customNumberComparison'].message }}
		</ng-container>
		<ng-container *ngIf="control.hasError('ngbDate')"> Ongeldige datum </ng-container>
		<ng-container *ngIf="control.hasError('ngbTime')"> Ongeldige tijd </ng-container>
		<ng-container *ngIf="control.hasError('customOneFieldRequired')">
			{{ control.errors['customOneFieldRequired'].message }}
		</ng-container>
		<ng-container *ngIf="control.hasError('customValidation')">
			{{ control.errors['customValidation'].message }}
		</ng-container>
	</ng-container>
	<!-- {{ control.errors | json }} -->
</div>
<div class="validation-message p-2 text-danger" *ngIf="control && control.invalid && control.hasError('nothingSelected')">
	<i class="fas fa-exclamation-triangle align-middle mr-1"></i>
	<ng-container *ngIf="control.hasError('nothingSelected')"> Veld is verplicht</ng-container>
</div>
