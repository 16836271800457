import { FirestoreEntityMetadata } from './../../base/firestore-entity-metadata';
export interface Website extends FirestoreEntityMetadata {
  teamId: string;
  title: string;
  subdomain: string;
  redirectTo: string;
  showContactInfo: boolean;
  alternateContactInfo: boolean;
  contactEmail: string;
  contactPhone: string;
  active: boolean;
}
