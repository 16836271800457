<form [formGroup]="form">
	<app-form-field [label]="label" [control]="form.controls.value">
		<div *ngIf="showClearButton && !disabled" class="input-group">
			<ng-container [ngTemplateOutlet]="inputElement"></ng-container>
			<button
				[ngClass]="{
					'btn-outline-secondary': form.controls.value.valid,
					'btn-outline-danger text-danger': form.controls.value.invalid
				}"
				class="btn"
				(click)="resetForm()"
				type="button">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
					<path
						d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
				</svg>
			</button>
		</div>
		<ng-container *ngIf="!showClearButton || disabled" [ngTemplateOutlet]="inputElement"></ng-container>
	</app-form-field>
	<ng-template #inputElement>
		<input
			type="text"
			class="form-control"
			formControlName="value"
			[placeholder]="placeholderValue"
			[ngbTypeahead]="search"
			[editable]="vrijeInvul"
			(focus)="focus$.next($any($event).target.value)"
			(click)="click$.next($any($event).target.value)"
			#instance="ngbTypeahead"
			[ngClass]="{ 'is-invalid': form.controls.value.invalid }" />
	</ng-template>
</form>
