import { ToastService } from './toast.service';
import { BaseService } from '../base/base.service';
import { Vacancy } from '@models';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth';

@Injectable({
  providedIn: 'root',
})
export class VacancyService extends BaseService<Vacancy> {
  protected readonly documentPath = '/vacancies';

  constructor(
    protected db: AngularFirestore,
    protected toastService: ToastService
  ) {
    super(db, toastService);
  }

  getTeamVacancies(teamId: string) {
    return this.getWithQuery((query) =>
      query
        .orderBy('endDate', 'asc')
        .where('teamId', '==', teamId)
        .where('active', '==', true)
        .where('endDate', '>', new Date())
    );
  }
}
