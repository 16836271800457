import { AdminGuard } from './core/guards/admin.guard';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { TemplateComponent } from './pages/static-content/template/template.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { isVacatureWebsite, isWlzTool } from 'src/app/core/helpers/url-routing';

const teamWebsiteRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/team-websites/team-websites.module').then(
            (m) => m.TeamWebsitesModule
          ),
      },
    ],
  },
  {
    path: 'admin',
    runGuardsAndResolvers: 'always',
    canActivateChild: [AdminGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import(
            './features/team-websites-admin/team-websites-admin.module'
          ).then((m) => m.TeamWebsitesAdminModule),
      },
    ],
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full',
  },
  { path: '**', redirectTo: '/' },
];

const vacancyRoutes: Routes = [
  { path: 'template', component: TemplateComponent },
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/home/home.module').then((m) => m.HomeModule),
      },
    ],
  },
  {
    path: 'admin',
    runGuardsAndResolvers: 'always',
    canActivateChild: [AdminGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/admin/admin.module').then((m) => m.AdminModule),
      },
    ],
  },
  {
    path: 'user',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/user/user.module').then((m) => m.UserModule),
      },
    ],
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full',
  },
  { path: '**', component: PageNotFoundComponent },
];

const wlzToolRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/wlz-tool/wlz-tool.module').then(
            (m) => m.WlzToolModule
          ),
      },
    ],
  },
  {
    path: 'admin',
    runGuardsAndResolvers: 'always',
    canActivateChild: [AdminGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import(
            './features/wlz-tool-admin/wlz-tool-admin.module'
          ).then((m) => m.WlzToolAdminModule),
      },
    ],
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full',
  },
  { path: '**', redirectTo: '/' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(
      isVacatureWebsite ? vacancyRoutes :  isWlzTool ? wlzToolRoutes : teamWebsiteRoutes,
      {
        enableTracing: false,
        scrollPositionRestoration: 'enabled',
        onSameUrlNavigation: 'reload',
        anchorScrolling: 'enabled',
        relativeLinkResolution: 'legacy',
        paramsInheritanceStrategy: 'always',
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
