import { EsResponse, EsSearchQuery } from '@models';
import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchReponse } from '../../models/search-response.interface';

@Injectable({ providedIn: 'root' })
export class SearchClient {
  private url = environment.searchApiUrl;
  private apiKey = environment.searchApiKey;

  constructor(private httpClient: HttpClient) {}

  getSearchResults(
    query: EsSearchQuery
  ): Observable<EsResponse<SearchReponse>> {
    const searchParameters = query.getSearchParameters();
    return this.httpClient.post<EsResponse<SearchReponse>>(
      this.url,
      searchParameters,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `ApiKey ${btoa(this.apiKey)}`,
        },
        // observe: 'response'
      }
    );
  }
}
