<table class="table table-responsive-md">
  <thead class="bg-light">
    <tr>
      <th scope="col">Datum</th>
      <th scope="col" *ngIf="!team">Team</th>
      <th scope="col">Vacature</th>
      <th scope="col">Kandidaat</th>
      <th scope="col" class="text-center">Status</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let jobApplication of jobApplications | slice: (page-1) * pageSize : page * pageSize">
      <td class="text-nowrap">{{ jobApplication.createdDate | date: 'shortDate' }}</td>
      <td *ngIf="!team">{{ jobApplication.vacancy?.teamName || 'Geen' }}</td>
      <td>
        <span class="badge badge-pill badge-success"
              *ngIf="jobApplication.isJobApplicationForward" placement="right"
              ngbPopover="Deze sollicitatie is vanuit een ander team of personeelszaken aan jullie doorgestuurd."
              popoverTitle="Doorgestuurde sollicitatie"><i
             class="fas fa-share fa-sm"></i></span>
        {{ jobApplication.vacancy?.jobTitleName || 'Open Sollicitatie' }}
      </td>
      <td>{{ jobApplication | fullname }}</td>
      <td class="text-center"><span class="badge" [ngClass]="jobApplication.statusColor">{{ jobApplication.statusName
          }}</span></td>
      <td class="text-nowrap text-right" *ngIf="!jobApplication.isJobApplicationForward else forward">
        <button [routerLink]="['/admin/sollicitaties/', jobApplication.documentId]"
                class="btn btn-sm btn-info mr-1"><i
             class="fas fa-search"></i>
          Bekijken
        </button>
      </td>
      <ng-template #forward>
        <td class="text-nowrap text-right" *ngIf="jobApplication.isJobApplicationForward">
          <button [routerLink]="['/admin/doorgestuurde-sollicitatie/', jobApplication.documentId]"
                  class="btn btn-sm btn-info mr-1"><i
               class="fas fa-search"></i>
            Bekijken
          </button>
        </td>
      </ng-template>
    </tr>
  </tbody>
</table>
<div class="d-flex justify-content-center" *ngIf="jobApplications?.length > 0">
  <ngb-pagination
                  [rotate]="true"
                  [boundaryLinks]="true"
                  [(page)]="page"
                  [pageSize]="pageSize"
                  [maxSize]="10"
                  [collectionSize]="jobApplications.length"></ngb-pagination>
</div>
