export interface SearchReponse {
  active: boolean;
  availabilityId: string;
  availabilityName: string;
  contactEmail: string;
  contactPhone: string;
  createdById: string;
  createdByName: string;
  createdDate: Date;
  distanceInKm: number;
  documentId: string;
  endDate: Date;
  htmlDescription: string;
  introductionText: string;
  jobTitleId: string;
  jobTitleLevel: string;
  jobTitleName: string;
  teamId: string;
  teamLocation: string;
  teamName: string;
  teamPhotoUrl: string;
}
