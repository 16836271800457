import { AppInjector } from './../../app.injector.module';
import { AuthService } from './../auth/auth.service';
import { OnDestroy, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ComponentBase } from './component-base';
import { LogService } from '../helpers/log.service';

@Directive()
export abstract class FormComponentBase extends ComponentBase implements OnDestroy {
	form: FormGroup;
	showInvalidFormMessage = false;
	isSaving = false;
	logSvc: LogService;

	get isFormDirty(): boolean {
		return this.form && this.form.dirty;
	}

	/** On closing tab/browser fire event that checks if a default browser confirm dialog should be shown  */
	// @HostListener('window:beforeunload', ['$event'])
	// unloadNotification($event: any) {
	//   if (this.isFormDirty) {
	//     $event.returnValue = true;
	//   }
	// }

	constructor(protected route: ActivatedRoute) {
		super(route);

		this.logSvc = AppInjector.get(LogService);
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	/** Tracks the validity of the form and resets the showInvalidFormMessage once the form is valid again: call after form is initialized! */
	trackFormValidity(): void {
		this.form.statusChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((formValidationStatus) => {
			if (this.showInvalidFormMessage && formValidationStatus === 'VALID') {
				this.showInvalidFormMessage = false;
			}
		});
	}

	/** Invalidates the reactive form */
	invalidateForm(opts?: { onlySelf?: boolean; emitEvent?: boolean }) {
		if (this.form) {
			Object.keys(this.form.controls).forEach((key) => {
				this.logSvc.log('[invalidateForm()] control:', key);
				this.form.get(key).markAsTouched();
				this.form.get(key).markAsDirty();
			});

			this.form.updateValueAndValidity(opts);

			this.showInvalidFormMessage = true;
		}
	}

	/** Resets the reactive form */
	resetForm(): void {
		if (this.form) {
			this.form.markAsPristine();
			this.showInvalidFormMessage = false;
		}
	}

	markAsTouchedAndDirty(form = undefined): void {
		const _form = form == null ? this.form : form;
		_form.markAllAsTouched();
		this.markDirtyAllControls(_form);
	}

	markDirtyAllControls(form: FormGroup): void {
		const recursiveFunc = (formGroup: FormGroup) => {
			Object.keys(formGroup.controls).forEach((field) => {
				const control = formGroup.get(field);
				if (control instanceof FormGroup) {
					recursiveFunc(control);
				}
				if (control && !control.dirty) {
					control.markAsDirty();
				}
			});
		};
		recursiveFunc(form);
	}

	getControl(formControlName: string): AbstractControl {
		return this.form.get(formControlName);
	}

	getArray(formControlName: string): FormArray {
		return this.form.get(formControlName) as FormArray;
	}

	isControlValid(formControlName: string): boolean {
		return this.getControl(formControlName).valid;
	}
}
