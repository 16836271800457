import { FirestoreEntityMetadata } from '../base/firestore-entity-metadata';

export interface JobTitle extends FirestoreEntityMetadata {
	name: string;
	level: string;
	introductionText: string;
	htmlDescription: string;
	isChecked?: boolean;
	beheerdersOnly?: boolean;
}
