import { LogService } from 'src/app/core/helpers/log.service';
import { take, tap, filter } from 'rxjs/operators';
import { Team } from '@models';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TeamService } from './api/team.service';

@Injectable({
	providedIn: 'root'
})
export class TeamSelectionService {
	private readonly selectedTeamIdKey = 'selectedTeamId';
	teamBehaviorSubject$ = new BehaviorSubject<Team>(undefined);
	private memberOfTeamsBehaviorSubject$ = new BehaviorSubject<Team[]>(undefined);
	get team() {
		return this.teamBehaviorSubject$.getValue();
	}
	team$ = this.teamBehaviorSubject$.asObservable();
	memberOfTeams$ = this.memberOfTeamsBehaviorSubject$.asObservable();

	constructor(private teamService: TeamService, private logService: LogService) {
		this.teamService.authorizedTeams$
			.pipe(
				filter((teams) => teams !== undefined),
				tap((teams) => this.logService.info('teamselectionservice', teams)),
				take(1)
			)
			.subscribe((teams) => {
				if (teams.length === 1) {
					this.setTeam(teams[0]);
				}
				this.memberOfTeamsBehaviorSubject$.next(teams);
			});

		const selectedTeamId = localStorage.getItem(this.selectedTeamIdKey);
		if (selectedTeamId) {
			this.teamService
				.getById(selectedTeamId)
				.pipe(take(1))
				.subscribe((team) => {
					console.log(team);
					this.teamBehaviorSubject$.next(team);
				});
		} else {
			this.teamBehaviorSubject$.next(null);
		}
	}

	setTeam(team: Team) {
		console.log('setTeam', team);
		this.teamBehaviorSubject$.next(team);
		if (team) {
			localStorage.setItem(this.selectedTeamIdKey, team.globalId);
		} else {
			/***
			 * There is no way to set a localStorage item to have a value of null.
			 * Use remove instead. Any localStorage item either has a string value, or it does not exist.
			 * No other type is possible, therefore no variation of null is possible either.
			 *
			 * localStorage.removeItem(this.selectedTeamIdKey, null);
			 */
			localStorage.removeItem(this.selectedTeamIdKey);
		}
	}
}
