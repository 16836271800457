import { AbstractControl } from '@angular/forms';

export const urlRegEx = /^(https?:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
export function urlValidator(c: AbstractControl) {
  if (!c.value) {
    // No value, handled by required validator
    return null;
  }

  return urlRegEx.test(c.value)
    ? null
    : {
        customUrl: {
          valid: false,
        },
      };
}

/** Validator that validates video url's for Youtube + Vimeo video links */
export function videoUrlValidator(c: AbstractControl) {
  if (!c.value) {
    // No value, handled by required validator
    return null;
  }

  const invalidResult = {
    customVideoUrl: {
      valid: false,
      message: 'Ongeldige video url',
    },
  };

  const videoUrl = c.value as string;
  if (videoUrl.includes('youtube.com') || videoUrl.includes('youtu.be')) {
    // This must be a Youtube video url, let's validate the known formats for a valid Youtube video link
    if (
      videoUrl.startsWith('https://www.youtube.com/watch?v=') ||
      videoUrl.startsWith('https://youtube.com/watch?v=')
    ) {
      if (videoUrl.substring(videoUrl.lastIndexOf('?v=')) !== '?v=') {
        return null;
      }
    }

    if (
      videoUrl.startsWith('https://www.youtube.com/embed/') ||
      videoUrl.startsWith('https://youtube.com/embed/') ||
      videoUrl.startsWith('https://youtu.be/')
    ) {
      if (videoUrl.substring(videoUrl.lastIndexOf('/')) !== '/') {
        return null;
      }
    }

    invalidResult.customVideoUrl.message = 'Ongeldige Youtube video url';
  }

  if (videoUrl.includes('vimeo.com')) {
    // This must be a Vimeo video url, let's validate the known formats for a valid Vimeo video link
    if (
      videoUrl.startsWith('https://www.vimeo.com/') ||
      videoUrl.startsWith('https://vimeo.com/') ||
      videoUrl.startsWith('https://player.vimeo.com/video/')
    ) {
      if (videoUrl.substring(videoUrl.lastIndexOf('/')) !== '/') {
        return null;
      }
    }

    invalidResult.customVideoUrl.message = 'Ongeldige Vimeo video url';
  }

  return invalidResult;
}

export const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export function emailValidator(c: AbstractControl) {
  if (!c.value) {
    // No value, handled by required validator
    return null;
  }

  return emailRegEx.test(c.value)
    ? null
    : {
        customEmail: {
          valid: false,
        },
      };
}

export const phoneRegEx = /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/;
export function phoneValidator(c: AbstractControl) {
  if (!c.value) {
    // No value, handled by required validator
    return null;
  }
  return phoneRegEx.test(c.value)
    ? null
    : {
        customPhone: {
          valid: false,
        },
      };
}

export function passwordValidator(c: AbstractControl) {
  if (!c.value) {
    // No value, handled by required validator
    return null;
  }

  const invalidResult = {
    customPassword: {
      valid: false,
      message: 'Het wachtwoord is ongeldig',
    },
  };

  if (!/[A-Z]+/.test(c.value)) {
    // Minimum 1 capital
    invalidResult.customPassword.message = 'Minimaal 1 hoofdletter';
    return invalidResult;
  }

  if (!/[a-z]+/.test(c.value)) {
    // Minimum 1 capital
    invalidResult.customPassword.message = 'Minimaal 1 kleine letter';
    return invalidResult;
  }

  if (!/[0-9]+/.test(c.value)) {
    // Minimum 1 number
    invalidResult.customPassword.message = 'Minimaal 1 cijfer';
    return invalidResult;
  }

  if (!/[!,@,$,%,&]+/.test(c.value)) {
    // Minimum 1 special character
    invalidResult.customPassword.message =
      'Minimaal 1 vreemd teken (!,@,$,%,&)';
    return invalidResult;
  }

  if (c.value.length < 8) {
    // minimal password length is 8 characters
    invalidResult.customPassword.message = 'Minimaal 8 karakters';
    return invalidResult;
  }

  return null;
}

export const zipcodeRegex = /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/;
export function dutchZipcodeValidator(c: AbstractControl) {
  if (!c.value) {
    // No value, handled by required validator
    return null;
  }

  return zipcodeRegex.test(c.value)
    ? null
    : {
        customDutchZipcode: {
          valid: false,
        },
      };
}
