import { PersonalInfo } from './personal-info.interface';
import { FirestoreEntityMetadata } from '../base/firestore-entity-metadata';
import { Vacancy } from './vacancy.interface';

export interface JobApplication extends PersonalInfo, FirestoreEntityMetadata {
  curriculumVitaeUrl: string;
  statusId: string;
  statusName: string;
  statusColor: string;
  statusDescription: string;
  notes: string;
  securityToken: string;
  vacancy: Vacancy;

  /// Only used for lists
  isJobApplicationForward?: boolean;
}
