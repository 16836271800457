import { NgbCustomDateParserFormatter } from './core/helpers/ngb-custom-date-parser-formatter';
import { NgbCustomDateAdapter } from './core/helpers/ngb-custom-date-adapter';
import { CoreModule } from './core/core.module';
import { StaticContentModule } from './pages/static-content/static-content.module';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgbModule, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { SharedModule } from './shared/shared.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';

import { environment } from '../environments/environment';
import { QuillModule } from 'ngx-quill';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';

registerLocaleData(localeNL);

@NgModule({
	declarations: [AppComponent, PageNotFoundComponent, ForbiddenComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule,
		AngularFireStorageModule,
		AngularFireAnalyticsModule,
		QuillModule.forRoot({
			placeholder: 'Voeg hier je tekst toe..',
			modules: {
				toolbar: [
					['bold', 'italic', 'underline', 'strike'], // toggled buttons
					// ['blockquote', 'code-block'],

					// [{ 'header': 1 }, { 'header': 2 }],               // custom button values
					// tslint:disable-next-line: object-literal-key-quotes
					[{ list: 'ordered' }, { list: 'bullet' }],
					// [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
					// tslint:disable-next-line: object-literal-key-quotes
					[{ indent: '-1' }, { indent: '+1' }], // outdent/indent
					// [{ 'direction': 'rtl' }],                         // text direction

					// [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
					// tslint:disable-next-line: object-literal-key-quotes
					[{ header: [1, 2, 3, 4, 5, 6, false] }],

					// [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
					// [{ 'font': [] }],
					// tslint:disable-next-line: object-literal-key-quotes
					[{ align: [] }],

					// ['clean'],                                         // remove formatting button

					// ['link', 'image', 'video']                         // link and image, video
					['link'] // link
				]
			}
		}),
		AppRoutingModule,
		NgbModule,
		CoreModule,
		StaticContentModule,
		SharedModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerImmediately'
		})
	],
	providers: [
		Title,
		{ provide: LOCALE_ID, useValue: 'nl' },
		{ provide: NgbDateAdapter, useClass: NgbCustomDateAdapter },
		{ provide: NgbDateParserFormatter, useClass: NgbCustomDateParserFormatter }

		// { provide: BUCKET, useValue: 'my-bucket-name' }
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
