import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';

export let AppInjector: Injector;

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class AppInjectorModule {
  constructor(injector: Injector) {
    AppInjector = injector;
  }
}
