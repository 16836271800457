import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

const noop = (): any => undefined;

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private debugLogEnabled = environment.debugLogEnabled;
  private baseStyle = 'font-weight: bold; padding: 2px;';

  constructor() {}

  get log(): (...params: any[]) => any {
    if (this.debugLogEnabled) {
      return console.log.bind(
        console,
        '%c[log]',
        this.baseStyle + 'background-color:gray; color: white'
      );
    } else {
      return noop;
    }
  }

  get info(): (...params: any[]) => any {
    if (this.debugLogEnabled) {
      return console.log.bind(
        console,
        '%c[info]',
        this.baseStyle + 'background-color:dodgerblue; color: white'
      );
    } else {
      return noop;
    }
  }

  get success(): (...params: any[]) => any {
    if (this.debugLogEnabled) {
      return console.log.bind(
        console,
        '%c[success]',
        this.baseStyle + 'background-color:green; color: white'
      );
    } else {
      return noop;
    }
  }

  get danger(): (...params: any[]) => any {
    if (this.debugLogEnabled) {
      return console.log.bind(
        console,
        '%c[danger]',
        this.baseStyle + 'background-color:red; color: white'
      );
    } else {
      return noop;
    }
  }

  get warning(): (...params: any[]) => any {
    if (this.debugLogEnabled) {
      return console.log.bind(
        console,
        '%c[warning]',
        this.baseStyle + 'background-color:yellow; color: black;'
      );
    } else {
      return noop;
    }
  }
}
