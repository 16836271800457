import { isVacatureWebsite, isWlzTool } from 'src/app/core/helpers/url-routing';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppUpdateService } from './core/services/app-update.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'Werken-Bij-Buurtzorg-Template';

	constructor(private titleService: Title) {}

	ngOnInit(): void {
		if (isVacatureWebsite) {
			this.titleService.setTitle('Werken bij Buurtzorg');
		} else if (isWlzTool) {
			this.titleService.setTitle('Wlz formulier');
		} else {
			this.titleService.setTitle('Buurtzorg Teamwebsite');
		}
	}
}
