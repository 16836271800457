import { switchMap, map, filter, takeUntil, take, find } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Website, WebsitePage } from '@models';
import { BaseService, ToastService } from '@services';
import { Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { WebsitePageTypeService } from './website-page-type.service';
import { TeamService } from '../team.service';
import { getWebsiteUrl } from 'src/app/core/helpers/url-routing';

@Injectable({
  providedIn: 'root',
})
export class WebsiteService extends BaseService<Website> {
  protected readonly documentPath = 'websites';
  private websites: Website[];

  constructor(
    protected db: AngularFirestore,
    protected toastService: ToastService,
    protected websitepageTypeService: WebsitePageTypeService,
    protected teamService: TeamService,
    private router: Router
  ) {
    super(db, toastService);
  }

  /**
   * This function gets the website from the current subdomain. It uses a cached list of subdomains which is retrieved at the first request.
   * Returns null when the subdomain is not found.
   *
   * @returns Observable<Website | null>
   */
  getWebsiteFromSubdomain(): Observable<Website | null> {
    this.logService.log('[getWebsiteFromSubdomain]');
    const urlFragments = location.hostname.split('.');
    if (urlFragments.length > 2) {
      const subdomain = urlFragments[0];
      this.logService.info('[getWebsiteFromSubdomain] subdomain:', subdomain);
      return this.getAllCached().pipe(
        switchMap((websites) => {
          this.websites = websites;
          let fIndex = websites.findIndex((s) => s.subdomain === subdomain);
          if (fIndex > -1) {
            let website = websites[fIndex];
            this.logService.info(
              '[getWebsiteFromSubdomain] website found:',
              website.title
            );

            // check if website needs to be redirected
            if (website.redirectTo) {
              this.logService.info(
                '[getWebsiteFromSubdomain] needs to be redirected:',
                website.redirectTo
              );
              fIndex = websites.findIndex(
                (s) => s.subdomain === website.redirectTo
              );
              website = websites[fIndex];
              this.logService.info(
                '[getWebsiteFromSubdomain] redirecting to website:',
                website.title
              );
              window.location.href = getWebsiteUrl(website.subdomain);
              return EMPTY;
            }

            // check if website is enabled
            if (website.active) {
              return of(website);
            } else {
              return of(null);
            }
          } else {
            return this.createDefaultWebsite(subdomain);
          }
        }),
        switchMap((website) => {
          if (!website) {
            return of(null);
          }
          if (website.redirectTo) {
            window.location.href = website.redirectTo;
            return of(null);
          } else {
            return of(website);
          }
        })
      );
    } else {
      return of(null);
    }
  }

  createDefaultWebsite(subdomain: string): Observable<Website> {
    this.logService.info('[createDefaultWebsite] subdomain:', subdomain);
    return this.teamService.getTeamFromSubdomain(subdomain).pipe(
      map((teams) => {
        if (teams.length > 0) {
          this.logService.info('[createDefaultWebsite] team:', teams);
          let team = teams[0];
          let website = {
            teamId: team.globalId,
            title: team.teamnaam,
            active: true,
            alternateContactInfo: false,
            showContactInfo: true,
            subdomain: subdomain,
            redirectTo: null,
          } as Website;

          return website;
        } else {
          return null;
        }
      })
    );
  }

  getByTeamId(teamId: string) {
    return this.getWithQuery((query) =>
      query.where('teamId', '==', teamId)
    ).pipe(map((websites) => (websites.length > 0 ? websites[0] : null)));
  }

  getWebsitePages(websiteId: string) {
    return this.db
      .collection<Website>(this.documentPath)
      .doc<Website>(websiteId)
      .collection<WebsitePage>('pages', (queryFn) =>
        queryFn.orderBy('order', 'asc')
      )
      .valueChanges()
      .pipe(
        map((changes) => {
          return changes.map((entity) => {
            return this.convertFirestoreTimeStamps(entity);
          });
        })
      );
  }

  getWebsitePageById(websiteId: string, websitePageId: string) {
    return this.getByIdFromSubCollection<WebsitePage>(
      websiteId,
      'pages',
      websitePageId
    );
  }

  addPage(websiteId: string, page: WebsitePage) {
    return this.addToSubcollection(websiteId, 'pages', page);
  }

  updatePage(websiteId: string, page: WebsitePage) {
    return this.updateSubCollectionDoc(websiteId, 'pages', page);
  }

  deletePage(websiteId: string, pageId: string) {
    return this.removeFromSubcollection(websiteId, 'pages', pageId);
  }
}
