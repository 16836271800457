<section class="buurtzorg-header-section">
  <div class="buurtzorg-image-header">
    <div class="buurtzorg-logo">Buurtzorg</div>
    <div class="buurtzorg-quote">
      <div class="buurtzorg-quote-title">
        Bij Buurtzorg heb ik het plezier in mijn werk
        weer teruggevonden.
      </div>
      <div class="buurtzorg-quote-author">
        Anneke Jansen<br />
        Wijkverpleegkundige
      </div>
    </div>
  </div>
  <div class="buurtzorg-filter-container">
    <div class="buurtzorg-filter">
      <div class="row">
        <div class="col-md-4">
          <div class="buurtzorg-baangenerator">ikbuurtzorg.baangenerator </div>
          <div class="buurtzorg-baangenerator">vind de fijnste baan voor jou</div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-4">
              <select class="buurtzorg-select d-block w-100" id="functie" required>
                <option value="">kies je functie</option>
                <option>Wijkziekenverzorgende</option>
                <option>Verpleegkundige in de wijk</option>
                <option>Wijkverpleegkundige</option>

              </select>
            </div>
            <div class="col-md-4">
              <select class="buurtzorg-select d-block w-100" id="locatie" required>
                <option value="">kies je locatie</option>
                <option>Amsterdam</option>
                <option>Alkmaar</option>
                <option>Almere</option>
                <option>Amstelveen</option>
              </select>
            </div>

            <div class="col-md-4">
              <button class="btn btn-primary btn-block"><i class="fas fa-search"></i> 312 VACATURES</button>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</section>


<section class="buurtzorg-section">
  <div class="buurtzorg-section-container">
    <div class="buurtzorg-logo">Buurtzorg</div>
    <div class="row justify-content-md-center">
      <div class="col-md-12 text-center">
        <h2>WIE WIJ ZIJN EN WAT WIJ DOEN</h2>
        <p class="lead mt-3">
          Met meer dan 14.000 collega’s zijn wij de grootste thuiszorgaanbieder van Nederland.
          Dat klinkt ‘groots’, maar zo voelt het niet: niet voor onze cliënten en niet voor onszelf. We werken namelijk
          vanuit
          meer dan 1000 kleine, zelfstandige teams en
          organiseren de zorg dichtbij onze cliënten. Dit doen we vanuit een heel heldere visie: De Beste Zorg Thuis.
          En,
          het
          werkt.
        </p>
        <div class="my-5">
          <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/11266224"
              style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0"
              allow="autoplay; fullscreen" allowfullscreen></iframe></div>
          <script src="https://player.vimeo.com/api/player.js"></script>

          <!-- <iframe src="https://player.vimeo.com/video/11266224" width="640" height="360" frameborder="0"
            allow="autoplay; fullscreen" allowfullscreen></iframe> -->
          <!-- <iframe allow="autoplay; fullscreen" allowfullscreen="" frameborder="0" width="100%"
            src="https://player.vimeo.com/video/301810459?color=ffffff&title=0&byline=0&portrait=0"></iframe> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="buurtzorg-section bg-light">
  <div class="row">
    <div class="col-lg-8">

      <div class="buurtzorg-section-container">
        <div class="buurtzorg-logo">Buurtzorg</div>
        <div class="container-fluid mb-3">

          <h2>EEN GREEP UIT ONZE VACATURES</h2>
          <div class="row mt-5">

            <div class="col-md-4 mb-3">
              <div class="card shadow">
                <div class="card-header bg-primary text-white">
                  <h5>Wijkverpleegkundige</h5>
                  <span>Team Zwolle 2</span>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 d-flex flex-column">
                      <div>
                        <dl class="row">
                          <dt class="col-sm-6 text-primary"><i class="fas fa-clock text-primary"></i>&nbsp;
                            <strong>Uren</strong> </dt>
                          <dd class="col-sm-6">In overleg</dd>
                          <dt class="col-sm-6 text-primary"><i class="fas fa-map-marker-alt text-primary"></i>&nbsp;
                            <strong>Locatie</strong> </dt>
                          <dd class="col-sm-6">Zwolle</dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <a href="#"><i class="fas fa-chevron-right"></i> Bekijk vacature</a>
                  </div>
                </div>
                <!-- <div class="card-footer">
                  <a href="#"><i class="fas fa-chevron-right"></i> Bekijk vacature</a>
                  <button type="button" class="btn btn-block btn-primary">Direct solliciteren</button>
                </div> -->
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <div class="card shadow">
                <div class="card-header bg-primary text-white">
                  <h5>Wijkverpleegkundige</h5>
                  <span>Team Zwolle 2</span>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 d-flex flex-column">
                      <div>
                        <dl class="row">
                          <dt class="col-sm-6 text-primary"><i class="fas fa-clock text-primary"></i>&nbsp;
                            <strong>Uren</strong> </dt>
                          <dd class="col-sm-6">In overleg</dd>
                          <dt class="col-sm-6 text-primary"><i class="fas fa-map-marker-alt text-primary"></i>&nbsp;
                            <strong>Locatie</strong> </dt>
                          <dd class="col-sm-6">Zwolle</dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <a href="#"><i class="fas fa-chevron-right"></i> Bekijk vacature</a>
                  </div>
                </div>
                <!-- <div class="card-footer">
                  <a href="#"><i class="fas fa-chevron-right"></i> Bekijk vacature</a>
                  <button type="button" class="btn btn-block btn-primary">Direct solliciteren</button>
                </div> -->
              </div>
            </div>


            <div class="col-md-4 mb-3">
              <div class="card shadow">
                <div class="card-header bg-primary text-white">
                  <h5>Wijkverpleegkundige</h5>
                  <span>Team Zwolle 2</span>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 d-flex flex-column">
                      <div>
                        <dl class="row">
                          <dt class="col-sm-6 text-primary"><i class="fas fa-clock text-primary"></i>&nbsp;
                            <strong>Uren</strong> </dt>
                          <dd class="col-sm-6">In overleg</dd>
                          <dt class="col-sm-6 text-primary"><i class="fas fa-map-marker-alt text-primary"></i>&nbsp;
                            <strong>Locatie</strong> </dt>
                          <dd class="col-sm-6">Zwolle</dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <a href="#"><i class="fas fa-chevron-right"></i> Bekijk vacature</a>
                  </div>
                </div>
                <!-- <div class="card-footer">
                  <a href="#"><i class="fas fa-chevron-right"></i> Bekijk vacature</a>
                  <button type="button" class="btn btn-block btn-primary">Direct solliciteren</button>
                </div> -->
              </div>
            </div>

          </div>
          <div class="row mt-5">
            <div class="col-md-4 mb-3">
              <div class="card shadow">
                <div class="card-header bg-primary text-white">
                  <h5>Wijkverpleegkundige</h5>
                  <span>Team Zwolle 2</span>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 d-flex flex-column">
                      <div>
                        <dl class="row">
                          <dt class="col-sm-6 text-primary"><i class="fas fa-clock text-primary"></i>&nbsp;
                            <strong>Uren</strong> </dt>
                          <dd class="col-sm-6">In overleg</dd>
                          <dt class="col-sm-6 text-primary"><i class="fas fa-map-marker-alt text-primary"></i>&nbsp;
                            <strong>Locatie</strong> </dt>
                          <dd class="col-sm-6">Zwolle</dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex">
                    <a href="#"><i class="fas fa-chevron-right"></i> Bekijk vacature</a>
                  </div>
                </div>
                <!-- <div class="card-footer">
                  <a href="#"><i class="fas fa-chevron-right"></i> Bekijk vacature</a>
                  <button type="button" class="btn btn-block btn-primary">Direct solliciteren</button>
                </div> -->
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <div class="card shadow">
                <div class="card-header bg-primary text-white">
                  <h5>Wijkziekenverzorgende</h5>
                  <span>Team Almelo Centrum</span>
                </div>
                <div class="card-body">
                  <div class="row mb-3 text-center">
                    <div class="col-md-6">
                      <h3><i class="fas fa-map-marker-alt text-primary"></i></h3>
                      <div><strong>Locatie</strong></div>
                      Almelo
                    </div>
                    <div class="col-md-6">
                      <h3><i class="far fa-clock text-primary"></i></h3>
                      <div><strong>Uren</strong></div>
                      12 - 24 uur
                    </div>
                  </div>
                  <div class="d-flex">
                    <a href="#"><i class="fas fa-chevron-right"></i> Bekijk vacature</a>
                  </div>
                </div>
                <div class="card-footer">
                  <button type="button" class="btn btn-block btn-success">Direct solliciteren</button>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <div class="card shadow">
                <div class="card-header bg-primary text-white">
                  <h5>Verpleegkundige in de wijk</h5>
                  <span>Team Groningen West</span>
                </div>
                <div class="card-body">
                  <div class="row mb-3 text-center">
                    <div class="col-md-6">
                      <h3><i class="fas fa-map-marker-alt text-primary"></i></h3>
                      <div><strong>Locatie</strong></div>
                      Groningen
                    </div>
                    <div class="col-md-6">
                      <h3><i class="far fa-clock text-primary"></i></h3>
                      <div><strong>Uren</strong></div>
                      Oproepkracht
                    </div>
                  </div>
                  <div class="d-flex">
                    <a href="#"><i class="fas fa-chevron-right"></i> Bekijk vacature</a>
                  </div>
                </div>
                <div class="card-footer">
                  <button type="button" class="btn btn-block btn-info">Direct solliciteren</button>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-start">
            <button type="button" class="btn btn-primary btn-lg"><i class="fas fa-chevron-right"></i> Naar alle
              vacatures</button>
          </div>
        </div>

      </div>
    </div>
    <div class="col-lg-4 background-image1">
    </div>
  </div>
</section>

<section class="buurtzorg-full-height-section bg-white">
  <div class="buurtzorg-section-container">
    <div class="buurtzorg-logo">Buurtzorg</div>
    <div class="row justify-content-md-center">
      <div class="col-md-12 text-center">
        <h2>VOLGENDE SECTIE MET CONTENT</h2>
        <p class="lead mt-3">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque in gravida arcu, ac elementum magna.
          Integer non scelerisque sapien. Sed viverra tincidunt sagittis. Quisque ut bibendum sem, eget semper orci.
          Nulla ac imperdiet arcu, sed varius ante. Nunc placerat massa ac urna sodales, ac semper arcu scelerisque.
          Donec ornare, neque a porta viverra, nisi leo vulputate tellus, vitae scelerisque lacus metus sit amet mauris.
        </p>
        <!-- <div class="my-5">
          <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/11266224"
              style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0"
              allow="autoplay; fullscreen" allowfullscreen></iframe></div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div> -->
      </div>
    </div>
  </div>
</section>

<section class="buurtzorg-footer-section position-relative">
  <div class="buurtzorg-logo">Buurtzorg</div>
  <div class="buurtzorg-image-footer">

    <div class="footer-slogan">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Menselijkheid boven bureaucratie</h4>
          Bij Buurtzorg zijn wij trots op de bevlogenheid en de deskundigheid van onze medewerkers waardoor ze iedere
          dag
          opnieuw de beste zorg leveren. Het is elke dag opnieuw een mooie belevenis om bij cliënten thuis de best
          mogelijke ondersteuning te bieden en dit samen met collega’s te kunnen organiseren.

        </div>
      </div>
    </div>


  </div>
</section>


<footer class="py-5 text-muted text-center text-small">
  <div class="d-flex justify-content-center">
    <h2 class="px-2"><i class="fab fa-facebook-square"></i></h2>
    <h2 class="px-2"><i class="fab fa-linkedin"></i></h2>
    <h2 class="px-2"><i class="fab fa-twitter"></i></h2>
  </div>
  <p class="mb-1">&copy; {{currentYear}} Stichting Buurtzorg Nederland</p>
</footer>
