<div class="card">
  <div class="card-header bg-primary text-light">
    <h5 class="mb-0">Contactformulier</h5>
  </div>
  <div class="card-body">
    <form [formGroup]="form" *ngIf="form">
      <div class="row">
        <div class="col-md-12 mb-3">
          <app-form-field [control]="form.controls.fullname" label="Naam">
            <input type="text" class="form-control" formControlName="fullname" />
          </app-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 mb-3">
          <app-form-field [control]="form.controls.email" label="Email">
            <input type="text" class="form-control" formControlName="email" />
          </app-form-field>
        </div>
        <div class="col-md-6 mb-3">
          <app-form-field [control]="form.controls.phonenumber" label="Telefoon">
            <input type="text" class="form-control" formControlName="phonenumber" />
          </app-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <app-form-field [control]="form.controls.subject" label="Onderwerp">
            <input type="text" class="form-control" formControlName="subject" />
          </app-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mb-3">
          <app-form-field [control]="form.controls.notes" label="Vragen / Opmerkingen">
            <textarea class="form-control" formControlName="notes" rows="5"></textarea>
          </app-form-field>
        </div>
      </div>

    </form>
  </div>
  <div class="card-footer">
    <div class="d-flex justify-content-end">
      <button type="button"
              class="btn btn-primary"
              [disabled]="isSaving"
              (click)="save()" *ngIf="website"><i class="fas fa-paper-plane"></i> Versturen</button>
    </div>
  </div>
</div>