import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SortDirection } from '@models';
import { SortState } from '../../core/models/sort-state';

@Directive({
  selector: '[sortable]',
  host: {
    '[class.asc]':
      'sortState?.direction === "asc" && sortState?.propertyName === sortable',
    '[class.desc]':
      'sortState?.direction === "desc"  && sortState?.propertyName === sortable',
    '(click)': 'flip()',
  },
})
export class SortableHeader {
  @Input() sortable: string;
  @Input() sortState: SortState;
  @Output() sortStateChange = new EventEmitter<SortState>();

  flip() {
    const test = {
      propertyName: this.sortable,
      direction:
        this.sortState.direction === SortDirection.Ascending
          ? SortDirection.Descending
          : SortDirection.Ascending,
    };
    this.sortStateChange.emit(test);
  }
}
