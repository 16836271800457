<div class="modal-header">
  <h4 class="modal-title">Sollicitatie doorsturen</h4>
  <button type="button" class="close" nofocus (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    Kies een team waar je deze sollicitatie aan wilt doorsturen. Het team krijgt automatisch een email over de
    doorgestuurde sollicitatie.
  </p>
  <div class="form-group">
    <div class="d-flex justify-content-between">
      <label class="input__label mb-2">Team</label>
    </div>
    <select class="custom-select d-block w-100" [(ngModel)]="selectedId">
      <option selected [ngValue]="null">- Kies een team -</option>
      <option *ngFor="let t of teams" [ngValue]="t.globalId">{{t.teamnaam}}</option>
    </select>
    <small class="form-text text-muted"></small>
  </div>

</div>
<div class="modal-footer justify-content-between">
  <button type="button" class="btn btn-secondary" (click)="cancel()">Annuleren</button>
  <button type="button" class="btn btn-success" (click)="save()">Versturen</button>
</div>
