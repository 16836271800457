import { JobApplicationForward } from './../../core/models/job-application-forward';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { ComponentBase } from '@base';
import { JobApplication, Team } from '@models';
import { TeamService, UserService } from '@services';
import { LogService } from 'src/app/core/helpers/log.service';
import { AuthService } from '@auth';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-team-forward-modal',
  templateUrl: './team-forward-modal.component.html',
  styleUrls: ['./team-forward-modal.component.scss']
})
export class TeamForwardModalComponent extends ComponentBase implements OnInit {
  @Input() jobApplication: JobApplication;
  teams: Team[];
  selectedId = '';

  constructor(
    private teamService: TeamService,
    public activeModal: NgbActiveModal,
    protected route: ActivatedRoute,
    protected authService: AuthService,
    private userService: UserService,
    private logService: LogService
  ) {
    super(route);

    this.teamService
      .getWithQuery(query => query.where('productief', '==', true))
      .pipe(
        map((teams) => teams.filter((team) => !team.email == false)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((teams) => {
        this.teams = teams;
        this.teams.sortOnProperty('teamnaam', 'ascending');
      });
  }

  ngOnInit() {}

  cancel() {
    this.activeModal.close('');
  }

  save() {
    const selectedTeam = this.teams.find((team) => team.globalId === this.selectedId);
    if (selectedTeam) {
      this.activeModal.close(selectedTeam);
    } else {
      this.activeModal.close(null);
    }
  }
}
