<form [formGroup]="form">
  <div class="row">
    <div class="col-md-12 mb-3 d-flex align-items-center">
      <app-form-field [control]="form.controls.gender" label="Aanhef">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="gender" id="exampleRadios1"
                 value="Dhr.">
          <label class="form-check-label" for="exampleRadios1">
            Dhr.
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="gender" id="exampleRadios2"
                 value="Mevr.">
          <label class="form-check-label" for="exampleRadios2">
            Mevr.
          </label>
        </div>
      </app-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2 mb-3">
      <app-form-field [control]="form.controls.initials" label="Voorletters">
        <input type="text" class="form-control" formControlName="initials" />
      </app-form-field>
    </div>
    <div class="col-md-6 mb-3">
      <app-form-field [control]="form.controls.firstname" label="Voornaam">
        <input type="text" class="form-control" formControlName="firstname" />
      </app-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2 mb-3">
      <app-form-field [control]="form.controls.lastnamePrefix" label="Tussenvoegsels">
        <input type="text" class="form-control" formControlName="lastnamePrefix" />
      </app-form-field>
    </div>
    <div class="col-md-6 mb-3">
      <app-form-field [control]="form.controls.lastname" label="Achternaam">
        <input type="text" class="form-control" formControlName="lastname" />
      </app-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 mb-3">
      <app-form-field [control]="form.controls.email" label="Email">
        <input type="text" class="form-control" formControlName="email" />
      </app-form-field>
    </div>
    <div class="col-md-6 mb-3">
      <app-form-field [control]="form.controls.phonenumber" label="Telefoon">
        <input type="text" class="form-control" formControlName="phonenumber" />
      </app-form-field>
    </div>
  </div>
</form>


<!-- <app-debug-form [form]="form"></app-debug-form> -->
