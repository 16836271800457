import { ApplicationRef, Injectable, OnInit } from '@angular/core';
import { withModule } from '@angular/core/testing';
import { SwUpdate } from '@angular/service-worker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '@shared';
import { timer } from 'rxjs';
import { first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
	providedIn: 'root'
})
export class AppUpdateService {
	constructor(swUpdate: SwUpdate) {
		if (!swUpdate.isEnabled) return;
		console.log('Init app update service...');
		swUpdate.available.subscribe((v) => {
			console.log('Updating app...');
			window.location.reload();
		});
		swUpdate.unrecoverable.subscribe((x) => console.log('Update seems to be broken: ' + x.reason));
		timer(0, 60000)
			.pipe(
				tap((_) => {
					console.log('Checking for update...');
					swUpdate.checkForUpdate();
				})
			)
			.subscribe();
	}
}
