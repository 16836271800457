import { ActivatedRoute } from '@angular/router';
import { OnDestroy, Directive } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive()
export abstract class ComponentBase implements OnDestroy {
  protected readonly unsubscribe$ = new Subject();

  constructor(protected route: ActivatedRoute) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  getIdFromUrl(): Observable<string> {
    return this.route.paramMap.pipe(map((params) => params.get('id')));
  }
}
