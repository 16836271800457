import { TeamContact } from './../../../models/team-websites/team-contact';
import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { BaseService, ToastService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class TeamContactService extends BaseService<TeamContact> {
  protected readonly documentPath = 'websitesTeamContact';

  constructor(
    protected db: AngularFirestore,
    protected toastService: ToastService
  ) {
    super(db, toastService);
  }
}
