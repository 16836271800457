import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: any[] = [];

  show(
    header: string,
    textOrTpl: string | TemplateRef<any>,
    options: any = {}
  ) {
    this.toasts.push({ header, textOrTpl, ...options });
  }

  showInfo(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.show('Informatie', textOrTpl, {
      classname: 'bg-info text-light',
      mode: 'info',
      delay: 3000,
      ...options,
    });
  }

  showSuccess(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.show('Succes!', textOrTpl, {
      classname: 'bg-success text-light',
      mode: 'success',
      delay: 3000,
      ...options,
    });
  }

  showError(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.show('Foutmelding', textOrTpl, {
      classname: 'bg-danger text-light',
      mode: 'error',
      delay: 7000,
      ...options,
    });
  }

  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
