import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { ModalOptions } from '../modal/modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-stock-teamphoto-picker',
  templateUrl: './stock-teamphoto-picker.component.html',
  styleUrls: ['./stock-teamphoto-picker.component.scss'],
})
export class StockTeamphotoPickerComponent implements OnInit {
  @Input() title: string;
  @Input() body: string | TemplateRef<any>;
  @Input() options: ModalOptions;

  stockPhotos = [
    { name: 'photo1', url: '/assets/images/stockphoto1.jpg' },
    { name: 'photo2', url: '/assets/images/stockphoto2.jpg' },
    { name: 'photo3', url: '/assets/images/stockphoto3.jpg' },
    { name: 'photo4', url: '/assets/images/stockphoto4.jpg' },
    { name: 'photo5', url: '/assets/images/stockphoto5.jpg' },
    { name: 'photo6', url: '/assets/images/stockphoto6.jpg' },
    { name: 'photo7', url: '/assets/images/stockphoto7.jpg' },
    { name: 'photo8', url: '/assets/images/stockphoto8.jpg' },
  ];

  selectedPhotoUrl: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  save() {
    this.activeModal.close(this.selectedPhotoUrl);
  }

  selectPhoto(photoUrl: string) {
    console.log('photoUrl:', photoUrl);
    this.selectedPhotoUrl = photoUrl;
  }
}
