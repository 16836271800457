import { SortDirection } from '@models';

const compare = (v1: string | number, v2: string | number) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

export const sort = (items: any[], column: string, direction: SortDirection): any[] => {
	if (items == null) return items;
	if (!direction || direction === SortDirection.None || !column || column === '') return items;

	return [...items].sort((a, b) => {
		let targetProperty = column;
		if (column.includes('.')) {
			// iterate through nested properties to get the proper targetProperty
			const properties = column.split('.').filter((v) => v !== '.');
			let objectProperties = Object.getOwnPropertyNames(items[0]);
			properties.forEach((property) => {
				targetProperty = objectProperties.find((v) => v === property);
				objectProperties = Object.getOwnPropertyNames(items[0][targetProperty]);
			});
		}
		const res = compare(a[targetProperty], b[targetProperty]);
		return direction === SortDirection.Ascending ? res : -res;
	});
};

export const filterList = (items: any[], searchTerm: string, fieldsToSearch: string[]): any[] => {
	if (items == null || searchTerm == null || searchTerm === '') return items;
	const filteredItems: any[] = [];
	fieldsToSearch.forEach((fieldToSearch) => {
		items.forEach((item) => {
			if (item[fieldToSearch]?.toLowerCase().includes(searchTerm?.toLowerCase()) && !filteredItems.includes(item)) {
				filteredItems.push(item);
			}
		});
	});
	return filteredItems;
};

export const paginate = (items: any[], pageSize: number, page: number) => {
	if (items == null) return items;
	return [...items]?.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
};

// Check if array is null, undefined or empty.
export const isNullOrEmpty = (list: any[]) => {
	return list == null || list.length < 1;
};

// Replace existing items with provided ones matching id.
export const replaceFromList = (items: any[], updatedItems: any[] | any): any[] => {
	if (updatedItems instanceof Array) return items.filter((i) => !updatedItems.includes(i));
	return items.filter((i) => i.id !== (updatedItems as any).id).concat(updatedItems);
};

export const getFromList = (items: any[], id: any): any => {
	return items.find((i) => i.id === id);
};

export const deleteFromList = (items: any[], deletedItems: any[] | any): any[] => {
	if (deletedItems instanceof Array) return items.filter((i) => deletedItems.includes((di) => di.id === i.id));
	return items.splice(
		items.findIndex((di) => di.id === deletedItems.id),
		1
	);
};

export const filterDuplicates = (items: any[], fieldToMatch: string = undefined): any[] => {
	if (fieldToMatch) return items?.filter((thing, i, arr) => arr.findIndex((t) => t[fieldToMatch] === thing[fieldToMatch]) === i);
	return items?.filter((thing, i, arr) => arr.findIndex((t) => t === thing) === i);
};
