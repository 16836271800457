<div class="mb-3" *ngFor="let item of vacancies">
  <div class="row no-gutters border rounded">
    <div class="col-4 image-vacature" [style.background-image]="'url(' + item.teamPhotoUrl + ')'"
         *ngIf="item.teamPhotoUrl !== null && item.teamPhotoUrl.length > 0; else NoTeamPhotoTmpl">
      <div class="image-overlay">
        <!-- <div><i class="fas fa-map-marker-alt text-white"></i> {{item.teamName}}</div> -->
        <div><i class="fas fa-business-time text-white"></i> {{item.availabilityName}} </div>
      </div>
    </div>
    <ng-template #NoTeamPhotoTmpl>
      <div class="col-4 image-vacature no-photo">
        <div class="image-overlay">
          <!-- <div><i class="fas fa-map-marker-alt text-white"></i> {{item.teamName}}</div> -->
          <div><i class="fas fa-business-time text-white"></i> {{item.availabilityName}} </div>
        </div>
      </div>
    </ng-template>
    <div class="col-8 p-3">
      <p class="mb-1">
        <span class="text-primary font-weight-bold">Team {{item.teamName}}</span>
      </p>
      <h5 class="font-weight-bold">{{item.jobTitleName}} <span class="h6 text-muted">Niveau
          {{item.jobTitleLevel}}</span></h5>
      <p>
        {{item.introductionText}}
      </p>
      <div class="d-flex justify-content-end">
        <a type="button" class="btn btn-primary"
           [href]="'http://werkenbijbuurtzorg.nl/vacature/' + item.documentId" target="_blank">Bekijk
          vacature</a>
      </div>
    </div>
  </div>
</div>