<div class="modal-header">
  <h4 class="modal-title">Standaard afbeelding</h4>
  <button type="button" class="close" nofocus (click)="activeModal.dismiss(null)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <p>Hebben jullie nog geen (team)foto? Dan kun je een keuze maken uit een van de standaard afbeeldingen.</p>

  <div class="d-flex flex-wrap">
    <div class="p-2" *ngFor="let photo of stockPhotos">
      <img class="img-fluid" [src]="photo.url" [class.selectedPhoto]="selectedPhotoUrl == photo.url"
           (click)="selectPhoto(photo.url)">
    </div>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close(null)">Annuleren</button>
  <button type="button" class="btn btn-success" (click)="save()" [disabled]="!selectedPhotoUrl">Opslaan</button>
</div>
