import { FirestoreEntityMetadata } from '../base/firestore-entity-metadata';

export interface Vacancy extends FirestoreEntityMetadata {
	title: string;
	introductionText: string;
	htmlDescription: string;

	/** The vacancy will be automaticly be taken offline after this date */
	endDate: Date;

	beheerdersOnly?: boolean;

	jobTitleId: string;
	jobTitleName: string;
	jobTitleLevel: string;

	teamPhotoUrl: string;
	teamName: string;
	teamLat: number;
	teamLon: number;

	teamId: string;

	availabilityId: string;
	availabilityName: string;

	/** Only active vacancies will be displayed on the website */
	active: boolean;

	contactEmail: string;
	contactPhone: string;

	distanceInMetres?: number;

	expiredMailSent: boolean;
}
