<owl-carousel-o [options]="customOptions">
  <ng-template carouselSlide [width]="500" *ngFor="let story of stories | async">
    <div class="slide rounded" role="button" (click)="showStory(story)">
      <div><img
             [src]="story.imageUrl"
             class="img-fluid carousel-slide-image">
        <div class="image-overlay text-white px-4">
          <p class="m-0">{{story.title}}</p>
          {{story.subtitle}}
        </div>
      </div>
      <div class="px-4 py-4">
        <p>{{story.lead}}</p>
        <button type="button" class="btn btn btn-outline-primary mt-4" (click)="showStory(story)">Lees meer <i
             class="fas fa-chevron-right"></i></button>
      </div>
    </div>
  </ng-template>
</owl-carousel-o>
