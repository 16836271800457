import { CoreModule } from './../core/core.module';
import { FooterComponent } from './footer/footer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { ModalComponent } from '@shared';
import { ToastsContainerComponent } from './toasts-container/toasts-container.component';
import {
  NgbToastModule,
  NgbProgressbar,
  NgbAlertModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FormFieldComponent } from './forms/form-field/form-field.component';
import { ValidationMessageComponent } from './forms/validation-message/validation-message.component';
import { DebugFormComponent } from './forms/debug-form/debug-form.component';
import { TeamSelectionModalComponent } from './team-selection-modal/team-selection-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingComponent } from './loading/loading.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { UserProfileFormComponent } from './user-profile-form/user-profile-form.component';
import { PrefooterComponent } from './prefooter/prefooter.component';
import { ClientsCarouselComponent } from './clients-carousel/clients-carousel.component';
import { StoryModalComponent } from './story-modal/story-modal.component';
import { StockTeamphotoPickerComponent } from './stock-teamphoto-picker/stock-teamphoto-picker.component';
import { JobApplicationsTableComponent } from './job-applications-table/job-applications-table.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TeamForwardModalComponent } from './team-forward-modal/team-forward-modal.component';
import { TeamContactComponent } from './team-websites/team-contact/team-contact.component';
import { TeamVacanciesComponent } from './team-websites/team-vacancies/team-vacancies.component';
import { SearchSelectorComponent } from './search-selector/search-selector.component';
import { SearchSelectorV2Component } from './search-selector-v2/search-selector-v2.component';

@NgModule({
  declarations: [
    ModalComponent,
    ToastsContainerComponent,
    FormFieldComponent,
    ValidationMessageComponent,
    DebugFormComponent,
    TeamSelectionModalComponent,
    LoadingComponent,
    LoginFormComponent,
    UserProfileFormComponent,
    PrefooterComponent,
    FooterComponent,
    ClientsCarouselComponent,
    StoryModalComponent,
    StockTeamphotoPickerComponent,
    JobApplicationsTableComponent,
    TeamForwardModalComponent,
    TeamVacanciesComponent,
    TeamContactComponent,
    SearchSelectorComponent,
    SearchSelectorV2Component
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbToastModule,
    CarouselModule,
    NgbAlertModule,
    CoreModule,
    NgbModule,
  ],
  exports: [
    ModalComponent,
    ToastsContainerComponent,
    FormFieldComponent,
    ValidationMessageComponent,
    DebugFormComponent,
    LoadingComponent,
    LoginFormComponent,
    UserProfileFormComponent,
    PrefooterComponent,
    FooterComponent,
    ClientsCarouselComponent,
    JobApplicationsTableComponent,
    TeamVacanciesComponent,
    TeamContactComponent,
    SearchSelectorComponent,
    SearchSelectorV2Component
  ],
})
export class SharedModule {}
