import { FirestoreEntityMetadata } from '../base/firestore-entity-metadata';
import { PersonalInfo } from './personal-info.interface';

export interface UserRoles {
  admin: boolean;
  employee: boolean;
  coach: boolean;
  wlzadmin: boolean;
}

export interface User extends PersonalInfo, FirestoreEntityMetadata {
  documentId: string;
  displayName: string;
  email: string;
  roles: UserRoles;
  regiocoachId?: number;
}
