import { FirestoreEntity } from '../base/firestore-entity';

export interface Story extends FirestoreEntity {
  title: string;
  subtitle: string;
  lead: string;
  imageUrl: string;
  videoUrl: string;
  body: string;
}
