import { map, filter, takeUntil, take, switchMap, tap } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { LogService } from '../helpers/log.service';

import { User } from '@models';
import { UserService } from '../services/api/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private unsubscribe$ = new Observable();
  private userServiceSubscription: Subscription;
  // Initial state of the behaviorSubject is undefined. This is important to be able to distinguish
  // between a null (not logged in) and undefined (current state unknown).
  private firebaseUserSubject$ = new BehaviorSubject<firebase.User | null>(undefined);
  firebaseUser$ = this.firebaseUserSubject$.asObservable();
  private userSubject$ = new BehaviorSubject<User | null>(undefined);
  user$ = this.userSubject$.asObservable();

  get user(): User {
    return this.userSubject$.getValue();
  }

  // loggedOut$ = new Subject();

  constructor(private afAuth: AngularFireAuth, private logService: LogService, private userService: UserService, private router: Router) {
    afAuth.credential
    .pipe(filter((userCredential) => !userCredential === false))
    .subscribe((userCredential) => {
      this.logService.info('afAuth.credentials:', userCredential);

      // Update displayName met profile data van de loginprovider (Google)
      const profile = userCredential.additionalUserInfo.profile;
      this.logService.info(
        'afAuth.userCredentials.additionalUserInfo.profile: ',
        profile
      );
      const displayName = profile['name'];
      const email = profile['email'];
      const emailVerified = profile['verified_email'];
      const photoURL = profile['picture'];

      // FirebaseUser update
      if (emailVerified && userCredential.user.email !== email) {
        this.logService.info(
          'emailVerified && userCredential.user.email !== email ', email
        );
        userCredential.user.updateEmail(email);
      }

      userCredential.user.updateProfile({
        displayName,
        photoURL,
      });

      this.userService
        .getById(userCredential.user.uid)
        .pipe(
          filter((gebruiker) => !gebruiker === false),
          take(1)
        )
        .subscribe((gebruiker) => {
        this.logService.info('userService subscribe', gebruiker);

          // Gebruiker update
          if (emailVerified && gebruiker.email !== email) {
            gebruiker.email = email;
          }
          gebruiker.displayName = displayName;
          this.userService
            .update(gebruiker)
            .then((result) =>
              this.logService.info('gebruiker update result:', result)
            );
        });
    });

    afAuth.onAuthStateChanged((fbUser: firebase.User) => {
      if (fbUser) {
        this.logService.info('[onAuthStateChanged] fbUser: ', fbUser);
        this.firebaseUserSubject$.next(fbUser);

        this.userServiceSubscription = this.userService
          .getById(fbUser.uid)
          .pipe(
            filter((user) => !user === false),
            take(1)
          )
          .subscribe((user) => this.userSubject$.next(user));
      } else {
        if (this.userServiceSubscription) {
          this.userServiceSubscription.unsubscribe();
        }
        this.logService.warning('[onAuthStateChanged] no user: ', fbUser);
        this.userSubject$.next(null);
        this.firebaseUserSubject$.next(null);
        // this.loggedOut$.next();
      }
    });
}

  // getUser(): Observable<User> {
  //   return this.firebaseUser$.pipe(
  //     filter((fbUser) => !fbUser === false),
  //     switchMap((fbUser) =>
  //       this.userService.getById(fbUser.uid).pipe(
  //         filter((user) => !user === false),
  //         takeUntil(this.loggedOut$)
  //       )
  //     ),
  //     takeUntil(this.loggedOut$)
  //   );
  // }

  login(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password).then((credentials) => {
      return credentials;
    });
  }

  loginWithGoogle(): Promise<firebase.auth.UserCredential> {
    return this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then((credentials) => {
      return credentials;
    });
  }

  async register(email: string, password: string) {
    const result = await this.afAuth.createUserWithEmailAndPassword(email, password);
    this.sendEmailVerification();
  }

  async sendEmailVerification() {
    (await this.afAuth.currentUser).sendEmailVerification();
    this.router.navigate(['/verify-email']);
  }

  async sendPasswordResetEmail(passwordResetEmail: string) {
    return await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
  }

  async logout() {
    await this.afAuth.signOut();
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.firebaseUserSubject$.unsubscribe();
    this.userSubject$.unsubscribe();
  }
}
