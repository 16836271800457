<ng-container *ngIf="!hidden">
  <div class="debug-form alert" [class.alert-success]="form.valid" [class.alert-danger]="!form.valid">
    <pre class="mb-0" *ngIf="raw">{{ form.getRawValue() | json }}</pre>
    <pre class="mb-0" *ngIf="!raw">{{ form.value | json }}</pre>

    <div class="debug-form__icon" [class.debug-form__icon--success]="form.valid" [class.debug-form__icon--danger]="!form.valid">
      <i class="icon-check" *ngIf="form.valid"></i>
      <i class="icon-close" *ngIf="!form.valid"></i>
    </div>
  </div>
</ng-container>
