import { FirestoreEntityMetadata } from './../../base/firestore-entity-metadata';
import { Website } from './website';
export interface TeamContact extends FirestoreEntityMetadata {
  fullname: string;
  address: string;
  zipcode: string;
  city: string;
  phonenumber: string;
  email: string;
  subject: string;
  notes: string;
  website: Website;
  websiteEmail: string;
}
