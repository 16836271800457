<div class="container-fluid">
  <div class="row">
    <div class="col-12 py-5 text-center">
      <div class="d-flex justify-content-center">
        <h1 class="mx-4">
          <a href="https://www.facebook.com/Buurtzorg-Nederland-201640399858073/" target="_blank"><i
               class="fab fa-facebook"></i></a>
        </h1>
        <h1 class="mx-4">
          <a href="https://www.linkedin.com/company/buurtzorg/" target="_blank"><i class="fab fa-linkedin"></i></a>
        </h1>
        <h1 class="mx-4">
          <a href="https://twitter.com/buurtzorg" target="_blank"><i class="fab fa-twitter"></i></a>
        </h1>
      </div>      
      <div class="my-2"><small><a href="https://buurtzorgnederland.com"> Buurtzorg Nederland</a></small></div>
      <div class="my-2"><small><a href="https://buurtzorg.net">Buurtzorg Teams</a></small></div>
      <div class="mb-2"><small><a href="https://keuringsdienstvanbanen.nl">Keuringsdienst van banen</a></small></div>
      <hr class="mb-5">
      
      <div class="mb-5"><small>Copyright © 2020 Buurtzorg Nederland. Alle rechten voorbehouden.</small></div>
    </div>
  </div>
</div>
