import { AbstractControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent implements OnInit {
  @Input() label = '[Label]';
  @Input() explanation: string;
  @Input() control: AbstractControl;
  @Input() hideValidationMessage: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
