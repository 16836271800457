<div class="container-fluid">
  <div class="row">
    <div class="col-12 sub-footer position-relative">
      <div class="card white-block">
        <div class="card-body">
          <h3 class="font-weight-bolder">Menselijkheid boven bureaucratie</h3>
          <p>Bij Buurtzorg zijn wij trots op de bevlogenheid en de deskundigheid van onze medewerkers waardoor ze
            iedere
            dag
            opnieuw de beste zorg leveren. Het is elke dag opnieuw een mooie belevenis om bij cliënten thuis de best
            mogelijke ondersteuning te bieden en dit samen met collega’s te kunnen organiseren.</p>
        </div>
      </div>
    </div>
  </div>
</div>
