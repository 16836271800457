import { Component, EventEmitter, Injector, Input, Optional, Output, Self, ViewChild, forwardRef } from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject, OperatorFunction, Observable, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-search-selector-v2',
  templateUrl: './search-selector-v2.component.html',
  styleUrls: ['./search-selector-v2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchSelectorV2Component),
      multi: true
    }
  ]
})
export class SearchSelectorV2Component implements ControlValueAccessor {

	@ViewChild('instance', { static: true }) instance: NgbTypeahead;

  @Input() vrijeInvulMogelijk = false;
  @Input() placeHolderValue: string;
  @Input() toonResetKnop = true;
  @Input() defaultValue: string = "";
  @Input() items: string[] = [];
  @Input() formControl: FormControl;
  @Input() formControlName: string;
  @Output() resetted = new EventEmitter();

  selectedItem: string;
  disabled = false;

  get isValid() {
    return this.control.valid
  }

  get control() {
    return this.formControl || this.controlContainer.control.get(this.formControlName);
  }

  onTouched: () => {};
  onChange: (value: string) => void;

	focus$ = new Subject<string>();
	click$ = new Subject<string>();

  constructor(private controlContainer: ControlContainer) {
  }

  writeValue(obj: any): void {
    this.selectedItem = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  reset() {
    this.selectedItem = this.defaultValue;
    this.onChange(this.selectedItem);
    this.resetted.emit();
  }

  selectItem(event: any) {
    this.writeValue(event.item);
    this.onChange(event.item);
    this.onTouched();
  }

	search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(100), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
		const inputFocus$ = this.focus$;
		return merge(inputFocus$, debouncedText$, clicksWithClosedPopup$).pipe(
			map((term) => {
        let items = this.items;
				if (term != null) items = items?.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1);
				if (term.length <= 2) items = items?.slice(0, 150); // Slice array because of rendering peformance with more items
				return items;
			})
		);
	};
}
function Ouput(): (target: SearchSelectorV2Component, propertyKey: "resetted") => void {
  throw new Error('Function not implemented.');
}

