import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { AppInjectorModule } from './../app.injector.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FullnamePipe } from './pipes/fullname.pipe';
import { SortableHeader } from './directive/sortable.directive';
import { AppUpdateService } from './services/app-update.service';

declare global {
	export interface Array<T> {
		sortOnProperty(property: keyof T, direction: 'ascending' | 'descending'): Array<T>;
	}
}

Array.prototype.sortOnProperty = function <T>(this: T[], property: keyof T, direction: 'ascending' | 'descending'): T[] {
	return this.sort((a, b) => {
		if (a[property] > b[property]) {
			return direction === 'ascending' ? 1 : -1;
		} else if (a[property] < b[property]) {
			return direction === 'ascending' ? -1 : 1;
		} else {
			return 0;
		}
	});
};

@NgModule({
	declarations: [FullnamePipe, SafeHtmlPipe, SortableHeader],
	imports: [CommonModule, AppInjectorModule, ServiceWorkerModule],
	providers: [FullnamePipe, SafeHtmlPipe, AppUpdateService],
	exports: [FullnamePipe, SafeHtmlPipe, SortableHeader]
})
export class CoreModule {}
