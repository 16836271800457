import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	EventEmitter,
	Input,
	Output,
	ViewChild,
	OnChanges,
	SimpleChanges
} from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormComponentBase } from '@base';
import { Team } from '@models';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

import { Subject, OperatorFunction, Observable, merge } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { filterDuplicates } from 'src/app/core/functions/arrays-functions';
import { SelectorValidator } from './validators/selector-validator';

@Component({
	selector: 'app-search-selector',
	templateUrl: './search-selector.component.html',
	styleUrls: ['./search-selector.component.scss']
})
export class SearchSelectorComponent extends FormComponentBase implements OnInit, OnChanges {
	@ViewChild('instance', { static: true }) instance: NgbTypeahead;

	@Input() placeholderValue = '[Placeholder]';
	@Input() required = false;
	@Input() label: string = '[Placeholder]';
	@Input() items: any[] | string[] = [];
	// Field that will be used as display value from items.
	@Input() displayFieldName: string = undefined;
	@Input() vrijeInvul = false;
	@Input() defaultValue: string;
	@Input() undefResetValue = false;
	@Input() disabled: boolean = false;
	// Should supplied list be filtered for duplicates?
	@Input() allowDuplicates = false;
	@Input() fixedWidth = true;
	@Input() showClearButton = true;

	@Output() state: EventEmitter<string> = new EventEmitter();
	focus$ = new Subject<string>();
	click$ = new Subject<string>();

	get formControl(): AbstractControl {
		return this.getControl('value');
	}

	private filteredItems: any[] | string[] = [];

	constructor(public activatedRoute: ActivatedRoute, private fb: FormBuilder) {
		super(activatedRoute);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.disabled?.currentValue === true ?? false) this.form?.disable();
	}

	ngOnInit(): void {
		this.initForm();
	}

	initForm(): void {
		this.form = this.fb.group({
			value: [this.defaultValue]
		});
		if (this.disabled === true) this.form.disable();
		if (this.required && !this.disabled) {
			this.getControl('value').addValidators([Validators.required]);
		}
		this.form.valueChanges
			.pipe(
				takeUntil(this.unsubscribe$),
				map((v) => (v?.value === this.placeholderValue ? undefined : v))
			)
			.subscribe((v) => this.state.emit(v?.value));
	}

	resetForm(): void {
		this.form.controls['value'].patchValue(this.undefResetValue ? undefined : this.defaultValue);
	}

	onFocus(value: any): void {
		this.focus$.next(value);
		if (this.formControl.value === this.placeholderValue) this.formControl.patchValue(undefined);
	}

	onBlur(): void {
		if (this.formControl.value == null) this.formControl.patchValue(this.placeholderValue);
	}

	search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
		const inputFocus$ = this.focus$;
		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			tap((term) => {
				this.filteredItems = this.items;
				if (this.displayFieldName != undefined) {
					let split = this.displayFieldName.split(',');
					if (split.length > 0) {
						this.filteredItems = this.items.map((a) => {
							let value = '';
							split.forEach((x) => (value += a[x] + ' '));
							return value.trim();
						});
					} else {
						this.filteredItems = this.items.map((a) => a[this.displayFieldName]);
					}
				}
				if (!this.allowDuplicates) this.filteredItems = filterDuplicates(this.filteredItems);
				if (term.length <= 2) this.filteredItems = this.filteredItems.slice(0, 150);
			}),
			map((term) =>
				term === this.placeholderValue
					? this.filteredItems
					: this.filteredItems.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
			)
		);
	};
}
