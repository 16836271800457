<ngb-toast
           *ngFor="let toast of toastService.toasts"
           [class]="toast.classname"
           [autohide]="true"
           [delay]="toast.delay || 5000"
           (hide)="toastService.remove(toast)">

  <ng-template ngbToastHeader>
    <ng-container [ngSwitch]="toast.mode">
      <div *ngSwitchCase="'info'">
        <i class="fas fa-info-circle text-info"></i> Informatie
      </div>
      <div *ngSwitchCase="'success'">
        <i class="fas fa-check-circle text-success"></i> Succes!
      </div>
      <div *ngSwitchCase="'error'">
        <i class="fas fa-exclamation-circle text-danger"></i> Foutmelding!
      </div>
      <div *ngSwitchDefault>
        <i class="fas fa-exclamation-circle text-danger"></i> Bericht
      </div>
    </ng-container>
  </ng-template>

  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>

  <ng-template #text>
    {{ toast.textOrTpl }}
  </ng-template>
</ngb-toast>
