import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbCustomDateAdapter extends NgbDateAdapter<Date> {
  fromModel(date: any): NgbDateStruct {
    if (date !== undefined && date !== null) {
      date = new Date(date);
    }
    const result = (date && date.getFullYear) ? {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    }
      : null;

    return result;
  }

  toModel(date: NgbDateStruct): Date {
    const result = date
      ? new Date(
        `${date.year}-${('00' + date.month).slice(-2)}-${(
          '00' + date.day
        ).slice(-2)}T00:00:00.000Z`
      )
      : null;
    return result;
  }
}
