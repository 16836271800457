<div class="modal-header">
	<h4 class="modal-title">{{ title }}</h4>
	<button type="button" class="close" nofocus (click)="activeModal.dismiss('close')">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	{{ body }}
</div>
<div class="modal-footer">
	<ng-container *ngIf="options.showCancel">
		<button type="button" class="btn btn-secondary" (click)="activeModal.close('cancel')">Annuleren</button>
	</ng-container>
	<ng-container *ngIf="options.showOk">
		<button type="button" class="btn btn-primary" (click)="activeModal.close('ok')">Oké</button>
	</ng-container>
	<ng-container *ngIf="options.showSave">
		<button type="button" class="btn btn-success" (click)="activeModal.close('save')">Opslaan</button>
	</ng-container>
	<ng-container *ngIf="options.showClose">
		<button type="button" class="btn btn-primary" (click)="activeModal.close('close')">Sluiten</button>
	</ng-container>
	<ng-container *ngIf="options.showInleveren">
		<button type="button" class="btn btn-primary" (click)="activeModal.close('inleveren')">Inleveren</button>
	</ng-container>
	<ng-container *ngIf="options.showVersturen">
		<button type="button" class="btn btn-primary" (click)="activeModal.close('versturen')">Versturen</button>
	</ng-container>
</div>
