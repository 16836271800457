import { environment } from 'src/environments/environment';

export const isVacatureWebsite: boolean =
	(window.location.hostname === 'localhost' && window.location.port === '4200') ||
	window.location.hostname === 'werkenbijbuurtzorg.nl' ||
	window.location.hostname === 'www.werkenbijbuurtzorg.nl' ||
	window.location.hostname === 'werken-bij-buurtzorg.web.app' ||
	window.location.hostname === 'werken-bij-buurtzorg.firebaseapp.com' ||
	window.location.hostname === 'werken-bij-buurtzorg-dev.web.app' ||
	window.location.hostname === 'werken-bij-buurtzorg-dev.firebaseapp.com' ||
	window.location.hostname === 'werkenbijbuurtzorg-dev.intellicare.nl';
export const isWlzTool: boolean =
	(window.location.hostname === 'localhost' && window.location.port === '4400') ||
	window.location.hostname === 'wlz-dev.intellicare.nl' ||
	window.location.hostname === 'wlz.intellicare.nl' ||
	// window.location.hostname === 'werken-bij-buurtzorg.web.app' ||
	window.location.hostname === 'wlz-tool-buurtzorg.firebaseapp.com';

export function getWebsiteUrl(subdomain: string = null) {
	const lastDot = location.host.lastIndexOf('.');
	const subdomainDot = location.host.lastIndexOf('.', lastDot - 1);
	let url: string;
	console.info('location:', location);
	if (subdomainDot == -1) {
		url = `${location.protocol}//${subdomain != null ? subdomain + '.' : ''}${location.host}`;
	} else {
		url = `${location.protocol}//${subdomain != null ? subdomain + '.' : ''}${location.host.slice(subdomainDot + 1)}`;
	}

	console.info('getWebsiteUrl(): ', url);
	return url;
}

export function getWebsiteDomain() {
	const lastDot = location.host.lastIndexOf('.');
	const subdomainDot = location.host.lastIndexOf('.', lastDot - 1);
	if (subdomainDot == -1) {
		return location.host;
	} else {
		return location.host.slice(subdomainDot + 1);
	}
}

export function getSubDomain() {
	const lastDot = location.hostname.lastIndexOf('.');
	const subdomainDot = location.hostname.lastIndexOf('.', lastDot - 1);
	if (subdomainDot == -1) {
		return null;
	} else {
		return location.hostname.slice(0, subdomainDot);
	}
}
