import { LogService } from 'src/app/core/helpers/log.service';
import { AuthService } from '@auth';
import { Team } from './../../models/team.interface';
import { BaseService } from './../base/base.service';
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from 'rxjs';
import { take, filter, switchMap, takeWhile, tap, map, catchError, takeUntil, shareReplay } from 'rxjs/operators';
import { ToastService } from './toast.service';
import { UserService } from './user.service';
import firebase from 'firebase/app';
import { extractSubdomain } from '../../helpers/subdomain-mask';

@Injectable({
  providedIn: 'root'
})
export class TeamService extends BaseService<Team> {
  protected readonly documentPath = 'teams';
  private productiveCached$: Observable<Team[]>;

  private userAuthorizedTeams$ = new BehaviorSubject<Team[]>(undefined);
  public authorizedTeams$ = this.userAuthorizedTeams$.asObservable();

  constructor(protected db: AngularFirestore, protected toastService: ToastService, private authService: AuthService) {
    super(db, toastService);

    this.authService.user$
      .pipe(
        filter((user) => !user === false),
        switchMap((user) => {
          const queryFn: QueryFn = (ref) => {
            let query: firebase.firestore.Query<firebase.firestore.DocumentData>;
            query = ref.orderBy('teamnaam', 'asc'); // of email adres?
            query = query.where('productief', '==', true);
            // query = query.where('email', '!=', null);

            if (user.roles.admin) {
              // geen extra filter omdat admin alle bestaande teams mag selecteren.
            } else if (user.roles.coach && user.regiocoachId && user.regiocoachId > 0) {
              query = query.where('regiocoachId', '==', user.regiocoachId);
            } else if (user.roles.employee) {
              query = query.where('members', 'array-contains', user.email);
            }
            return query;
          };
          return super.getWithQuery(queryFn);
        })
      )
      .subscribe((teams) => this.userAuthorizedTeams$.next(teams));
  }

  getAllProductiveCached(): Observable<Team[]> {
    if (!this.productiveCached$) {
      this.logService.warning('getAllCached');
      this.productiveCached$ = this.db
        .collection<Team>(this.documentPath, (query) => query.where('productief', '==', true).where('eindDatum', '>', new Date()))
        .get()
        .pipe(
          map((snapshot) => snapshot.docs),
          map((docs) => {
            return docs.map((doc) => this.convertFirestoreTimeStamps(doc.data()) as Team);
          }),
          map((teams) => teams.filter((team) => team.email != null && !team.verborgen)),
          shareReplay({ refCount: true, bufferSize: 1 })
        );
    } else {
      this.logService.success('getAllCached');
    }

    return this.productiveCached$;
  }

  getTeamFromSubdomain(subdomain: string) {
    return this.getAllCached().pipe(
      take(1),
      map(
        (teams) => teams.filter((team) => extractSubdomain(team.teamnaam) == subdomain && team.productief == true && team.email != null)
        // map((filtered) => filtered[0] || null)
      )
    );
  }

  getAllProductive(): Observable<Team[]> {
    return this.db
      .collection<Team>(this.documentPath, (query) => query.where('productief', '==', true).where('eindDatum', '>', new Date()))
      .valueChanges()
      .pipe(
        map((changes) => {
          return changes.map((entity) => {
            return this.convertFirestoreTimeStamps(entity);
          });
        }),
        catchError((err: any, caught: Observable<any>) => {
          return super.generalErrorHandler(err, caught);
        })
      );
  }
}
