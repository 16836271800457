import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ForbiddenComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
