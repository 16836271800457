<button type="button" class="btn btn-primary" (click)="loginWithGoogle()"><i
     class="fab fa-google"></i>
  Inloggen voor teams</button>

<!-- <div class="container d-flex justify-content-center">

  <div class="card shadow m-5">
    <div class="card-header bg-primary text-light">
      <h5 class="mb-0">Inloggen</h5>
    </div>
    <div class="card-body">
      <p>Hulp bij inloggen? Neem contact op met <a href="mailto:support@intellicare.nl">support@intellicare.nl</a></p>

      <form [formGroup]="form">
        <div class="row">
          <div class="col-md-6">
              <div class="form-group">
                <label>Email</label>
                <input type="text" class="form-control" formControlName="email">
              </div>
              <div class="form-group">
                <label>Wachtwoord</label>
                <input type="password" class="form-control" formControlName="wachtwoord">
              </div>
              <button type="button" class="btn btn-primary" (click)="localLogin()">Inloggen</button>
            </div>
          <div class="col-md-12">
            <button type="button" class="btn btn-block btn-primary" (click)="loginWithGoogle()"><i
                 class="fab fa-google"></i>
              Inloggen voor medewerkers</button>
          </div>
        </div>
      </form>

      <div class="alert-danger" *ngIf="errorMessage !== ''"><strong>Foutmelding</strong> {{errorMessage}}</div>

    </div>
  </div>
</div> -->
