import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface ModalOptions {
	showClose: boolean;
	showSave: boolean;
	showOk: boolean;
	showCancel: boolean;
	showInleveren: boolean;
  showVersturen: boolean;
}

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
	@Input() title: string;
	@Input() body: string;
	@Input() options: ModalOptions;

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit() {}

	isTemplateRef(object: any): boolean {
		return object instanceof TemplateRef;
	}
}
