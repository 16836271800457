import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[app-prefooter]',
  templateUrl: './prefooter.component.html',
  styleUrls: ['./prefooter.component.scss'],
})
export class PrefooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
