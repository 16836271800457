import { SortDirection } from './sort-direction';

export enum EsOperator {
  Or = 'OR',
  And = 'AND',
}

export interface EsSort {
  [key: string]: SortDirection | any;
}
export interface EsBool {
  /** The clause (query) must appear in matching documents and will contribute to the score. */
  must?: EsQuery[];

  /** The clause (query) must not appear in the matching documents. Clauses are executed in filter context meaning that
   * scoring is ignored and clauses are considered for caching. Because scoring is ignored, a score of 0 for all documents is returned.
   */
  must_not?: EsQuery[];

  /** The clause (query) should appear in the matching document. */
  should?: EsQuery[];

  /** The clause (query) must appear in matching documents. However unlike must the score of the query will be ignored.
   * Filter clauses are executed in filter context, meaning that scoring is ignored and clauses are considered for caching.
   */
  filter?: EsQuery;

  minimum_should_match: number;
}

export interface EsMultiMatch {
  query: string;
  fields?: string[];
  type?: string;
  fuzziness?: string;
}

export interface EsQueryObject {
  [key: string]: string | number;
}

export interface EsQuery {
  bool?: EsBool;
  match?: EsQueryObject[];
  match_phrase?: EsQueryObject;
  match_phrase_prefix?: EsQueryObject;
  match_all?: EsQueryObject;
  multi_match?: EsMultiMatch;
  term?: EsQueryObject;
  // range: EsRange[];
  docvalue_fields?: string;
  geo_distance: any;
}

export interface EsSource {
  includes: string[];
  excludes: string[];
}

export interface EsHighlightObject {
  [key: string]: any;
}

export interface EsHighlight {
  fields: EsHighlightObject;
  pre_tags: string[];
  post_tags: string[];
  number_of_fragments?: number;
  fragment_size?: number;
  order?: string;
}

export interface EsSuggestion {
  prefix: string;
  completion: EsSuggestionCompletion;
}

export interface EsContext {
  [key: string]: any;
}

export interface EsSuggestionCompletion {
  field: string;
  contexts: EsContext;
  fuzzy: { fuzziness: string };
  skip_duplicates: boolean;
  size: 10;
}

export interface EsSuggestions {
  [key: string]: EsSuggestion;
}

export interface EsSearchRequest {
  /** (Optional, string) You can use the _source parameter to select what fields of the source are returned.
   *  This is called source filtering.
   */
  _source: EsSource;

  suggest: EsSuggestions;

  /** (Optional, integer) Starting document offset. Defaults to 0. */
  from?: number;

  /** (Optional, integer) The number of hits to return. Defaults to 10. */
  size?: number;

  /** (Optional, string) A comma-separated list of <field>:<direction> pairs. */
  sort: EsSort[];

  /** If true, returns detailed information about score computation as part of a hit. Defaults to false. */
  explain?: boolean;

  query?: EsQuery;

  highlight?: EsHighlight;
}
