import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
@Component({
  selector: 'app-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
})
export class ValidationMessageComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() customMessage: string;

  constructor() {}

  ngOnInit(): void {}
}
