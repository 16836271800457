// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	debugLogEnabled: true,
	firebaseEmulator: true,
	production: false,
	appVersion: 1.1,
	googleMapsApiKey: 'AIzaSyCl9WHRbl1FdeKyaVtOoebHiGOBNcba53k',
	searchApiUrl: 'https://elasticsearch.intellicare.nl:9200/fb_dev/_search',
	searchApiKey: 'YUxYMnYBhmb4JL10mC6a:SR3D2-VdRRGcNM7b5N3nXw',
	firebase: {
		apiKey: 'AIzaSyCNGCsHfiMX4VEXZ8yA5b7p-3Jv6YyqDYU',
		authDomain: 'werken-bij-buurtzorg-dev.firebaseapp.com',
		projectId: 'werken-bij-buurtzorg-dev',
		storageBucket: 'werken-bij-buurtzorg-dev.appspot.com',
		messagingSenderId: '1064058343898',
		appId: '1:1064058343898:web:b457c86f3b6a632081b0e3'
	},
	toolbarOptions: {
		container: [
			['bold', 'italic', 'underline', 'strike'], // toggled buttons
			// ['blockquote', 'code-block'],

			// [{ header: 1 }, { header: 2 }], // custom button values
			[{ list: 'ordered' }, { list: 'bullet' }],
			[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
			[{ indent: '-1' }, { indent: '+1' }], // outdent/indent
			// [{ direction: 'rtl' }], // text direction

			// [{ size: ['small', false, 'large', 'huge'] }],  // custom dropdown
			[{ header: [3, 4, 5, 6, false] }],

			[{ color: [] }, { background: [] }], // dropdown with defaults from theme
			[{ font: [] }],
			[{ align: [] }],

			['clean'] // remove formatting button
		]
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
