import { PersonalInfo } from '../models/personal-info.interface';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullname',
})
export class FullnamePipe implements PipeTransform {
  transform(value: PersonalInfo, ...args: unknown[]): string {
    if (value) {
      if (value.lastnamePrefix && value.lastnamePrefix.length > 0) {
        return `${value.initials} ${value.lastnamePrefix} ${value.lastname}`;
      } else {
        return `${value.initials} ${value.lastname}`;
      }
    }
    return null;
  }
}
