import { StoryModalComponent } from './../story-modal/story-modal.component';
import { ModalComponent } from '@shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Story } from '@models';
import { Observable } from 'rxjs';
import { LogService } from 'src/app/core/helpers/log.service';

@Component({
  selector: 'app-clients-carousel',
  templateUrl: './clients-carousel.component.html',
  styleUrls: ['./clients-carousel.component.scss'],
})
export class ClientsCarouselComponent implements OnInit {
  @Input() stories: Observable<Story[]>;

  @ViewChild('storyModalTmpl')
  private storyModalTmpl: TemplateRef<any>;

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    nav: false,
  };

  constructor(private modalService: NgbModal, private logService: LogService) {}

  ngOnInit(): void {}

  showStory(story: Story): void {
    const modalRef = this.modalService.open(StoryModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.story = story;
    modalRef.result.then();
  }
}
