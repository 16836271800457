import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '@auth';
import firebase from 'firebase/app';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @Output() firebaseUser = new EventEmitter<firebase.User>();
  form: FormGroup;
  errorMessage = '';

  constructor(private fb: FormBuilder, private authService: AuthService) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', []],
      wachtwoord: ['', []],
    });
  }

  localLogin() {
    this.errorMessage = '';
    this.authService
      .login(
        this.form.controls.email.value,
        this.form.controls.wachtwoord.value
      )
      .then((userCredentials) => {
        this.firebaseUser.next(userCredentials.user);
      })
      .catch((err) => {
        this.errorMessage = err;
      });
  }

  loginWithGoogle() {
    this.errorMessage = '';
    this.authService.loginWithGoogle().then((userCredentials) => {
      this.firebaseUser.next(userCredentials.user);
    });
  }
}
