import { ToastService } from './../api/toast.service';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount, share, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import firebase from 'firebase/app';
import { FirestoreEntity } from '@base';
import { LogService } from '../../helpers/log.service';
import { AppInjector } from 'src/app/app.injector.module';
import { PaginationResult } from '../../models/pagination-result.interface';

export abstract class BaseService<T extends FirestoreEntity> {
  protected abstract documentPath: string;
  protected logService: LogService;
  private getAllCached$: Observable<T[]>;

  constructor(protected db: AngularFirestore, protected toastService: ToastService) {
    this.logService = AppInjector.get(LogService);
  }

  getById(id: string): Observable<T> {
    this.logService.info('[getById] path:' + this.documentPath + ' id: ');

    return this.db
      .collection(this.documentPath)
      .doc<T>(id)
      .snapshotChanges()
      .pipe(
        map((snapshot) => {
          return this.convertFirestoreTimeStamps(snapshot.payload.data() as T);
        })
      );
  }

  getSnapshotChanges(query: QueryFn): Observable<T[]> {
    this.logService.info('[getSnapshotChanges] ', this.documentPath);
    return this.db
      .collection<T>(this.documentPath, query)
      .get()
      .pipe(
        map((snapshot) => {
          return snapshot.docs.map((doc) => this.convertFirestoreTimeStamps(doc.data() as T));
        })
      );
  }

  getSnapshotChangesWithPagination(query: QueryFn): Observable<PaginationResult<T>> {
    this.logService.info('[getSnapshotChanges] path:' + this.documentPath);

    return this.db
      .collection<T>(this.documentPath, query)
      .get()
      .pipe(
        map((snapshot) => {
          const data = snapshot.docs.map((doc) => this.convertFirestoreTimeStamps(doc.data() as T));
          const pResult = {
            items: data,
            firstDoc: snapshot.docs[0],
            lastDoc: snapshot.docs[snapshot.docs.length - 1]
          } as PaginationResult<T>;
          return pResult;
        })
      );
  }
  // getSnapshotChanges(
  //   query: QueryFn
  // ): Observable<firestore.QuerySnapshot<firestore.DocumentData>> {
  //   this.logService.info('[getSnapshotChanges] ', this.documentPath);
  //   return this.db
  //     .collection<T>(this.documentPath, query)
  //     .get()
  //     .pipe(
  //       tap((_) =>
  //         this.logService.info('[getSnapshotChanges] path:' + this.documentPath)
  //       )
  //     );
  // }

  getAll(): Observable<T[]> {
    this.logService.info('[getAll] ', this.documentPath);

    return this.db
      .collection<T>(this.documentPath)
      .valueChanges()
      .pipe(
        map((changes) => {
          return changes.map((entity) => {
            return this.convertFirestoreTimeStamps(entity);
          });
        }),
        catchError((err: any, caught: Observable<any>) => {
          return this.generalErrorHandler(err, caught);
        })
      );
  }

  getAllCached(): Observable<T[]> {
    if (!this.getAllCached$) {
      this.logService.warning('getAllCached');
      this.getAllCached$ = this.db
        .collection<T>(this.documentPath)
        .get()
        .pipe(
          map((snapshot) => snapshot.docs),
          map((docs) => {
            return docs.map((doc) => this.convertFirestoreTimeStamps(doc.data()) as T);
          }),
          shareReplay({ refCount: true, bufferSize: 1 })
        );
    } else {
      this.logService.success('getAllCached');
    }

    return this.getAllCached$;
  }

  getAllWithPagination(startAt: T, limit: number): Observable<T[]> {
    this.logService.info('[getAllWithPagination] ', this.documentPath);
    return this.db
      .collection<T>(this.documentPath, (query) => query.startAt(startAt).limit(limit))
      .valueChanges()
      .pipe(
        map((changes) => {
          return changes.map((entity) => {
            return this.convertFirestoreTimeStamps(entity);
          });
        }),
        catchError((err: any, caught: Observable<any>) => {
          return this.generalErrorHandler(err, caught);
        })
      );
  }

  getWithQuery(queryFn: QueryFn): Observable<T[]> {
    this.logService.info('[getWithQuery] ', this.documentPath);
    return this.db
      .collection<T>(this.documentPath, queryFn)
      .valueChanges()
      .pipe(
        map((entities) => entities.map((entity) => this.convertFirestoreTimeStamps(entity))),
        catchError((err: any, caught: Observable<any>) => {
          return this.generalErrorHandler(err, caught);
        })
      );
  }

  createNewId(): string {
    this.logService.info('[createNewId] ', this.documentPath);
    return this.db.createId();
  }

  /**
   * Creates an ID which consists of 40 characters.
   * chances of guessing a correct ID is:
   *
   * 62 (alphanumeric upper- and lowercase, 0-9) ^ 40 = 4,962123624593670669143665801957e+71 possibilities
   *
   * If we generate 1 million ID's, the chance of guessing a correct one would be:
   *
   * (1.000.000 / 4,962123624593670669143665801957e+71) * 100 = 2,0152661957951242629177387777471e-64 %
   *
   */
  createNewBigId(): string {
    this.logService.info('[createNewBigId] ', this.documentPath);
    return this.db.createId() + this.db.createId();
  }

  create(data: T): Promise<void> {
    this.logService.success('[create] ', this.documentPath);
    if (!data.documentId) {
      data.documentId = this.db.createId();
    }

    // Set metadata properties
    // data.createdById = this.authService.user.uid;
    // data.createdByName = this.authService.user.displayName;
    // data.createdDate = new Date();

    return this.db.collection<T>(this.documentPath).doc(data.documentId).set(data);
  }

  delete(id: string): Promise<void> {
    this.logService.danger('[delete] ', this.documentPath);
    return this.db.collection<T>(this.documentPath).doc(id).delete();
  }

  update(data: T): Promise<void> {
    this.logService.success('[update] ', this.documentPath);
    // this.logService.info('[update data] ', data);

    // Set metadata properties
    // data.modifiedById = this.authService.user.uid;
    // data.modifiedByName = this.authService.user.displayName;
    // data.modifiedDate = new Date();

    return this.db.collection<T>(this.documentPath).doc(data.documentId).set(data, { merge: true });
  }

  getAllFromSubCollection<K>(parentId: string, subCollection: string): Observable<K[]> {
    this.logService.info('[getAllFromSubCollection] ', this.documentPath, '/', subCollection);

    return this.db
      .collection<T>(this.documentPath)
      .doc<T>(parentId)
      .collection<K>(subCollection)
      .valueChanges()
      .pipe(
        map((changes) => {
          return changes.map((entity) => {
            return this.convertFirestoreTimeStamps(entity);
          });
        }),
        catchError((err: any, caught: Observable<any>) => {
          return this.generalErrorHandler(err, caught);
        })
      );
  }

  getFromSubCollectionWithQuery<K>(parentId: string, subCollection: string, queryFn: QueryFn) {
    this.logService.info('[getFromSubCollectionWithQuery] ', this.documentPath, '/', subCollection);

    return this.db
      .collection<T>(this.documentPath)
      .doc<T>(parentId)
      .collection<K>(subCollection, queryFn)
      .valueChanges()
      .pipe(
        map((changes) => {
          return changes.map((entity) => {
            return this.convertFirestoreTimeStamps(entity);
          });
        }),
        catchError((err: any, caught: Observable<any>) => {
          return this.generalErrorHandler(err, caught);
        })
      );
  }

  addToSubcollection<K extends FirestoreEntity>(parentId: string, subCollection: string, data: K) {
    if (!data.documentId) {
      data.documentId = this.db.createId();
    }

    return this.db.collection<T>(this.documentPath).doc<T>(parentId).collection<K>(subCollection).doc<K>(data.documentId).set(data);
  }

  removeFromSubcollection<K extends FirestoreEntity>(parentId: string, subCollection: string, id: string) {
    return this.db.collection<T>(this.documentPath).doc<T>(parentId).collection<K>(subCollection).doc<K>(id).delete();
  }

  updateSubCollectionDoc<K extends FirestoreEntity>(parentId: string, subCollection: string, data: K) {
    return this.db
      .collection<T>(this.documentPath)
      .doc<T>(parentId)
      .collection<K>(subCollection)
      .doc<K>(data.documentId)
      .set(data, { merge: true });
  }

  getByIdFromSubCollection<K extends FirestoreEntity>(parentId: string, subCollection: string, id: string): Observable<K> {
    this.logService.info('[getFromSubCollectionWithQuery] ', this.documentPath, '/', subCollection, ' id: ', id);

    return this.db
      .collection<T>(this.documentPath)
      .doc<T>(parentId)
      .collection<K>(subCollection)
      .doc<K>(id)
      .snapshotChanges()
      .pipe(
        map((snapshot) => {
          return this.convertFirestoreTimeStamps(snapshot.payload.data() as K);
        })
      );
  }

  public convertFirestoreTimeStamps<K>(entity: K): K {
    // this.logService.info('[convertFirestoreTimeStamps] ', entity);
    if (entity) {
      Object.keys(entity).forEach((key) => {
        if (entity[key] instanceof firebase.firestore.Timestamp) {
          entity[key] = entity[key].toDate();
        }

        if (typeof entity[key] === 'object') {
          this.convertFirestoreTimeStamps(entity[key]);
        }
      });
    }
    return entity;
  }

  generalErrorHandler(err: any, caught: Observable<any>) {
    this.logService.danger(`[base.service] generalErrorHandler(): ${this.documentPath} \n${err}`);
    this.toastService.showError(err);
    return throwError(err);
  }
}
