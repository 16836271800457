import { JobApplicationStatus } from '../../models/job-application-status.interface';
import { ToastService } from './toast.service';
import { BaseService } from '../base/base.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JobApplicationStatusService extends BaseService<JobApplicationStatus> {
  protected readonly documentPath = '/jobApplicationStatuses';

  constructor(
    protected db: AngularFirestore,
    protected toastService: ToastService
  ) {
    super(db, toastService);
  }
}
