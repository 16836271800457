export interface EsrTotal {
  value: number;
  relation: string;
}

export interface EsrHits<T> {
  hits: EsrHit<T>[];
  max_score: number;
  total: EsrTotal;
}

export interface EsrHit<T> {
  _id: string;
  _index: string;
  _score: number;
  _source: T;
  _type: string;
  highlight: EsrHighlight;
}

export interface EsrHighlight {
  [key: string]: string[];
}

export interface EsResponse<T> {
  hits: EsrHits<T>;

  /** Indication if the search query timed-out. */
  'timed-out': boolean;

  /** Amount of time in milliseconds it took to accumulate the search results. */
  took: number; //

  /** Amount of time in milliseconds it took to accumulate the search results. */
  shards: any[];
}
