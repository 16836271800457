import { Team } from '../../../core/models/team.interface';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth';
import { FormComponentBase } from '@base';
import { FormMode } from '@enums';
import { TeamContact, Website } from '@models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TeamContactService, ToastService } from '@services';
import { ModalComponent } from '@shared';
import { LogService } from 'src/app/core/helpers/log.service';
import { phoneValidator, emailValidator } from 'src/app/core/validators';

@Component({
  selector: 'app-team-contact',
  templateUrl: './team-contact.component.html',
  styleUrls: ['./team-contact.component.scss'],
})
export class TeamContactComponent extends FormComponentBase implements OnInit {
  @Input() websiteEmail: string;
  @Input() website: Website;

  formMode: FormMode = FormMode.Create;
  documentId: string;
  form: FormGroup;
  teamContact: TeamContact;

  constructor(
    protected route: ActivatedRoute,
    protected authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private teamContactService: TeamContactService,
    private logService: LogService,
    private toastService: ToastService,
    private modalService: NgbModal
  ) {
    super(route);
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      fullname: new FormControl(null, [Validators.required]),
      phonenumber: new FormControl(null, [phoneValidator]),
      email: new FormControl(null, [Validators.required, emailValidator]),
      subject: new FormControl(null, [Validators.required]),
      notes: new FormControl(null, [Validators.required]),
    });

    this.trackFormValidity();
  }

  save() {
    if (this.form.valid) {
      this.isSaving = true;
      const model = this.form.value as TeamContact;
      model.documentId = this.teamContactService.createNewId();
      this.setAdditionalModelProperties(model, this.formMode);
      if (this.formMode === FormMode.Create) {
        this.teamContactService
          .create(model)
          .then(this.handleSaveSuccess, this.handleSaveFailed)
          .finally(() => (this.isSaving = false));
      }
    } else {
      this.invalidateForm();
    }
  }

  setAdditionalModelProperties(model: TeamContact, formMode: FormMode) {
    if (formMode === FormMode.Create) {
      model.createdDate = new Date();
    } else {
      model.modifiedDate = new Date();
    }
    model.website = this.website;
    model.websiteEmail = this.websiteEmail;
  }

  handleSaveSuccess = (): void => {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.title = 'Contactformulier verzonden';
    modalRef.componentInstance.options = {
      showOk: true,
    };
    modalRef.componentInstance.body =
      'Hartelijk dank voor je bericht! We nemen zo spoedig mogelijk contact met je op.';
    modalRef.result.then((result) => {
      this.form.reset();
    });
  };

  handleSaveFailed = (reason: any): void => {
    this.toastService.showError(
      'Er is een fout opgetreden. Foutmelding details:' + reason.toString()
    );
  };
}
