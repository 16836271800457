import { FirestoreEntity } from './firestore-entity';

export interface FirestoreEntityMetadata extends FirestoreEntity {
  createdDate: Date;
  createdByName: string;
  createdById: string;
  modifiedDate: Date;
  modifiedByName: string;
  modifiedById: string;
}
