import { FirestoreEntityMetadata } from '../base/firestore-entity-metadata';
export interface Team extends FirestoreEntityMetadata {
  id: number;
  teamnummer: string;
  teamnaam: string;
  startDatum: Date;
  eindDatum: Date;
  gebiedscode: string;
  gebiedsnummer: string;
  straat: string;
  huisnummer: string;
  huisnummerToev: string;
  postcode: string;
  plaats: string;
  telefoon: string;
  fax: string;
  bereikbaar1: string;
  bereikbaar2: string;
  email: string;
  email2: string;
  lon: number;
  lat: number;
  productief: boolean;
  regiocoachId: number;
  oe: number;
  globalId: string;
  afwijkendAdres: boolean;
  afwijkendStraat?: string;
  afwijkendHuisnummer?: string;
  afwijkendHuisnummerToev?: string;
  afwijkendPostcode?: string;
  afwijkendPlaats?: string;
  verborgen: boolean;
}
